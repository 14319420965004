import React,{useState,useEffect} from 'react';
// import { Spin , Pagination, Tabs } from 'antd';

import { Tabs, Mask, InfiniteScroll } from 'antd-mobile'
// import { LoadingOutlined } from '@ant-design/icons';
import axios from 'axios';
import './index.less'
// import Footer from '../../../compontents/footer';
const { TabPane } = Tabs;

function NewsContent(props) {

  const [newsData, setNewsData] = useState([]);
  const [tabActiveKey, setTabActiveKey] = useState('1');
  const [current, setCurrent] = useState(1);
  const [total, setTotal] = useState(0);
  const [loading, setLoading] = useState(false);
  const [hasMore, setHasMore] = useState(false);
  
  useEffect(()=>getNews(0,1),[])

  const getNews = (category,pageNumber) =>{ 
    setLoading(true)
    axios({
      headers: {
        'Content-Type': 'application/json'
      },
      method: 'GET',
      url: `/consumer-zhifubao/news/center?newsCategory=${category}&pageSize=10&pageNumber=${pageNumber}`,
      responseType: 'json',
    }).then((res)=>{
      if(res?.data?.code===1){
        setNewsData(res.data.data.page.records)
        setCurrent(res.data.data.page.current)
        setTotal(res.data.data.page.total)
        setLoading(false)
      }
    })
  }

  const loadMore = () => {
    const num = parseInt(newsData.length/10)+1 
    axios({
      headers: {
        'Content-Type': 'application/json'
      },
      method: 'GET',
      url: `/consumer-zhifubao/news/center?newsCategory=${tabActiveKey-1}&pageSize=10&pageNumber=${num}`,
      responseType: 'json',
    }).then((res)=>{
      if(res?.data?.code===1){
        setNewsData(...newsData, ...res.data.data.page.records)
        setCurrent(res.data.data.page.current)
        setTotal(res.data.data.page.total)
        // setLoading(false)
        setHasMore(res.data.data.page.records.length > 0)
      }
    })
  }

  const pageChange=num=>{
    getNews(tabActiveKey-1,num)
  }
  const onTabChange=key=>{
    setTabActiveKey(key)
    getNews(key-1,1)
  }

  // const antIcon = <LoadingOutlined style={{ fontSize: 48, color:'#EB3A32' }} spin />;

  return (
    <div className='newsCenter'>
      <div className='newsCenter-banner'>
        {/* <div className='center'> */}
          {/* <div className='place'></div> */}
          <div className='newsCenter-banner-title'>新闻中心</div>
          <div className='newsCenter-banner-tip'>Press Center</div>
        {/* </div> */}
      </div>
      <Tabs 
      className='newsCenter-newsTabs'
      // defaultActiveKey="1"
      defaultActiveKey={tabActiveKey}
      onChange={onTabChange}>
        <Tabs.Tab className='newsCenter-newsTabs-newsTabsPane' title='行业动态' key='1'>
        </Tabs.Tab>
        <Tabs.Tab title='公司新闻' key='2'>
          </Tabs.Tab>
        <Tabs.Tab title='商家案例' key='3'>
          </Tabs.Tab>
        <Tabs.Tab title='媒体报道' key='4'>
        </Tabs.Tab>
      </Tabs>
      <div className='newsCenter-container'>
      {newsData.map((item,index)=>
        <a key={index} className='newsCenter-container-content' href={item.url} target="_blank" rel="noreferrer">
          <div className='newsCenter-container-content-img'><img src={item.pictureUrl} alt=''/></div>
          <div className='newsCenter-container-content-info'>
            <h6 className='newsCenter-container-content-info-title'>{item.title}</h6>
            {/* <div className='newsCenter-container-content-info-index'>{item.content}</div> */}
            <div className='newsCenter-container-content-info-date'>{item?.updateTime?item.updateTime.split(' ')[0]:item.createTime.split(' ')[0]}</div>
            <div className='newsCenter-container-content-info-source'>{item.source}</div>
          </div>
        </a>
        )}
         <InfiniteScroll loadMore={loadMore} hasMore={hasMore} />
      </div>
          {/* <Mask visible={loading}>
      </Mask> */}
        {/* <Pagination className='newsPage' current={current} total={total} pageSize={3} onChange={pageChange}></Pagination> */}
        {/* <Footer /> */}
    </div>
  )
}

export default NewsContent;