import React, { useState, useEffect } from 'react';
import { Modal, Form, Input, Button,message} from 'antd';
import { CloseOutlined } from "@ant-design/icons"
import axios from 'axios'
import './index.less';

function Store(props) {
  const [form] = Form.useForm();
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [isModalShow, setIsModalShow] = useState(false);

  const handleCancel = () => {
    setIsModalVisible(false)
  }

  const handleClick = () => {
    setIsModalVisible(true)
  }
  const cancelModal = () => {
    setIsModalShow(false)
  }

  const showModal = () => {
    setIsModalShow(true)
  }
  const submitForm = (data) => axios({
    headers: {
      'Content-Type': 'application/json'
    },
    method: 'post',
    url: '/consumer-zhifubao/aliPay/user/submitUserConsultInfo',
    responseType: 'json',
    data
  })

  const onFinish = (values) => {
    form.resetFields();
    // console.log("666", values);
    submitForm({
      ...values
    }).then(res => {
      // console.log("666", res)
      if (res?.data?.code === 1) {
        setIsModalShow(false)
        message.success(res.data.msg);
      } else {
        message.error(res.data.msg);
      }
    //   if (res?.data?.code === 1) {
    //     setIsModalShow(false)
    //     Toast.show({
    //       icon: 'success',
    //       content: res.data.msg,
    //     });
    //   } else {
    //     Toast.show({
    //       icon: 'fail',
    //       content: res.data.msg,
    //     });
    //  }
    });
  };

  const storeiconList=[
    {
      title:"兔巴租机",
      content:"Bieber建的全品类店铺",
    },
    {
      title:"大叔数码",
      content:"全品类店铺",
    },
    {
      title:"熊猫绘本",
      content:"主营儿童绘本租赁服务",
    },
    {
      title:"租元璋",
      content:"电脑租赁",
    },
    {
      title:"恒乐嘉手机",
      content:"专业手机租赁",
    },
  ]

  return (
    <div className="store">
      <div className="store-container">
        {/* <Header setFlag={props.setFlag} flag={props.flag} setUrl={props.setUrl} url={props.url} /> */}
        <div className="store-container-header">
          <div className="store-container-header-title">租赁店铺 </div>
          <div className="store-container-header-top">
            一分钟免费拥有自己对租赁店铺
          </div>
          <div className="store-container-header-bottom"  onClick={showModal}>
            立即试用
          </div>
          <div className="store-container-header-mount"></div>
        </div>
        <div className='store-container-pic'></div>
      </div>
      <div className="store-content">
        <div className="store-content-process">
          <div className="store-content-process-title">全面构建租赁业务闭环</div>
          <div className="store-content-process-line"></div>
          <div className="store-content-process-tip">公私域引流、线上店铺、店铺营销、免押金下单、租中租后管理、资产保障、全面构建租赁业务闭环</div>
          {/* 租赁前/中/后 */}
        </div>
        <div className="blank-box" />
        <div className="store-content-process-img"/>
        <div className="store-content-first">
          <div className="store-content-title">公域流量引流，尽享业务增长</div>
          <div className="store-content-tip">
            上传商品享受惠租千万用户流量红利<br/>
            新店更有新店礼包，获得翻倍业务增长
          </div>
          <div className="store-content-first-button" size='marge'><Button onClick={handleClick}>立即咨询</Button></div>
          <div className="store-content-icon1"/>
        </div>
        <div className="store-content-second">
          <div className="store-content-title"> 0成本“一键开店”</div>
          <div className="store-content-tip">
              助力行业增长，0成本即可开店<br/>
              1分钟即可上传自己的商品<br/>
              店铺支持微信H5，支付宝小程序等多种形式<br/>
              助你获得全网流量，打造最佳用户体验
          </div>
        </div>
        <div className="store-content-button" size='marge'><Button onClick={handleClick}>立即咨询</Button></div>
        <div className="store-content-icon2"/>
      </div>
      <div className='store-step'><div className="fourSteps" /></div>
      <div className='store-title'>
          他们都在用惠租
          </div>
      <div className='store-tip'>排名不分先后顺序</div>
      <div className="icon-box">
        <div className="zhezhaoceng"></div>
        <div className="store-icons">
        {storeiconList.map((item)=>
            <div className="store-icon">
              <div className="icon">
                  <div className="icon-bk"></div>
                  <div className="icon-head"></div>
                  <div className="icon-title">{item.title}</div>
                  <div className="icon-content">{item.content}</div>
              </div>
            </div>
        )}
        </div>
      </div>
      <div className="store-service">
        {/* <div className="store-service-title">马上开始搭建您的线上店铺</div>
        <div className="store-service-button"><Button onClick={handleClick}>立即咨询</Button></div> */}

        <div className="store-service-title">立即扫码咨询，领取您的专属解决方案</div>
        <div className="hrs"/>
        <div className="store-service-action">
          <div className="code-icon" />
          <div className="service-content">
            <div className="content-first">马上扫码添加客户经理</div>
            <div className="content-second">或者您也可以先</div>
            <div className="content-third" onClick={showModal}>免费试用</div>
          </div>
          <div className="store-service-action-icon" />
        </div>
      </div>
      
      {/* <Modal
        visible={isModalVisible}
        //onCancel={handleCancel}
        footer={null}
        className="modalInquiry"
        getContainer={false}
        width={600}
        showCloseButton
        onClose={handleCancel}
        title='立即咨询'  
        content={(
          <>
          <div className="pic-box">
            <div className="pic"></div>
            <div className="tip">微信扫一扫</div>
          </div>
          <div className="content">
            微信扫一扫与客服经理 <br />
            一对一沟通开店
          </div>
          </>
        )}
      /> */}
      <Modal
        visible={isModalVisible}
        onCancel={handleCancel}
        footer={null}
        className="modalInquiry"
        getContainer={false}
        width={600}
      >
        <div className="title">立即咨询</div>
        <div className="pic-box">
          <div className="pic"></div>
          <div className="tip">微信扫一扫</div>
        </div>
        <div className="content">
          微信扫一扫与客服经理 <br />
          一对一沟通开店
        </div>
      </Modal>
      <Modal
        visible={isModalShow}
        onCancel={cancelModal}
        footer={null}
        className="modalAppointment"
       // title="立即预约"
        getContainer={false}
        width={570}
      >
        <Form name="basic" layout="horizontal" onFinish={onFinish} form={form} mode='card'>
          <div className="title">立即预约</div>
          <div className="form">
            <div className="form-div" style={{ marginTop: 20 }}>
              <span className="span" style={{ paddingRight: 5.97 }}>姓<span style={{ visibility: "hidden" }}>姓名</span>名：</span>
              <Form.Item name="contactName" rules={[{ required: true, message: '您的姓名为必填' }]}>
                <div className="inputs" style={{ marginLeft: -5.97 }}>
                  <Input type="text" placeholder="请输入您的姓名" className="input" />
                </div>
              </Form.Item>
            </div>
            <div className="form-div">
              <span className="span">企业名称：</span>
              <Form.Item name="enterpriseName" rules={[{ required: true, message: '企业名称为必填' }]}>
                <div className="inputs">
                  <Input type="text" placeholder="请输入企业名称" className="input" />
                </div>
              </Form.Item>
            </div>
            <div className="form-div">
              <span className="span">手机号码：</span>
              <Form.Item name="telephone" rules={[{ required: true, message: '手机号码为必填' }]}>
                <div className="inputs">
                  <Input type="text" placeholder="请输入手机号码" className="input" />
                </div>
              </Form.Item>
            </div>
            <div className="form-div">
              <span className="span">意向类目：</span>
              <Form.Item name="categories">
                <div className="inputs">
                  <Input type="text" placeholder="请输入意向类目，如：手机" className="input" />
                </div>
              </Form.Item>
            </div>
          </div>
          <div className="buttonBox">
            <Button className="button" htmlType="submit"  size='large'>提交信息</Button>
          </div>
        </Form>
      </Modal>
      {/* <Modal
        visible={isModalShow}
        //onCancel={cancelModal}
        footer={null}
        className="modalAppointment"
        showCloseButton
        onClose={cancelModal}
        getContainer={false}
        title='立即预约'
        width={570}
        // content={<ModalForm/>}
        content={(
          <>
           <Form layout="horizontal" className="basic" onFinish={onFinish} mode='card' name="form" footer={
             <Button block type="submit" color="primary" size="large">提交信息</Button>
           }>
          <Form.Item label="姓&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;名：" name="contactName" className="contact" rules={[{ required: true,message:'您的姓名为必填' }]} style={{ marginTop: 10 }}>
            <Input type="text"  className="contact-box-input" placeholder="请输入您的姓名" />
          </Form.Item>
          <Form.Item label="企业名称：" name="enterpriseName" className="contact" rules={[{ required: true,message:'企业名称为必填'}]} style={{ marginTop: 10 }}>
            <Input type="text" className="contact-box-input" placeholder="请输入企业名称" />
          </Form.Item>
          <Form.Item  label="手机号码：" name="telephone" className="contact" rules={[{ required: true , message:'手机号码为必填' }]} style={{ marginTop: 10 }}>
            <Input type="text" className="contact-box-input" placeholder="请输入手机号码" />
          </Form.Item>
          <Form.Item  label="意向类目：" name="categories" className="contact" style={{ marginTop: 10 }}>
            <Input type="text" className="contact-box-input" placeholder="请输入意向类目" />
          </Form.Item>
         
          </Form>
          
          </>

        )}
      /> */}
      {/* <Footer /> */}
    </div>
  )
}

export default Store;