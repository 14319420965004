import React from 'react';
import './index.less';
const ModalForm = () => {
    
    return(
        <div className="modalform">
            <div className="modalform-icon">
                <div className="modalform-icon-pic"></div>
                <div className="modalform-icon-title">微信扫一扫</div>
            </div>
            <div className="modalform-tip">
                基于神经网络算法的模型<br />
                为你的经营保驾护航
            </div>
        </div>
    )
}

export default ModalForm 