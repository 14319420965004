import React, { useState } from 'react';
import { Modal } from 'antd';
import Footer from '../../../compontents/footer';
import PcContent from './content';
import './index.less';

function PcPage(props) {

  const [isModalVisibles, setIsModalVisibles] = useState(false);




  const handleCancels = () => {
    setIsModalVisibles(false)
  }

  const handleClick = () => {
    setIsModalVisibles(true)
  }
// 头部首页
  return (

    <div className="container-store">
      <div className="header">
        {/* <div className="top-title">惠租 </div> */}
        <div className="top-content-top">
          PC独立站/APP
        </div>
        <div className="top-content-center">
          建立你自己的租赁网站/APP其实很简单
        </div>
        <div className="top-content-bottom" id="#lianxi">
          <a href="#lianxi" onClick={handleClick}> 立即咨询</a>
        </div>
        <div className="pic-left"></div>
        <div className="pic-right"></div>
      </div>

      {/* <Header setFlag={setFlag} /> */}

      <Modal
        visible={isModalVisibles}
        onCancel={handleCancels}
        footer={null}
        className="modalInquiry"
        getContainer={false}
        width={600}
      >
        <div className="title">立即咨询</div>
        <div className="pic-box">
          <div className="pic"></div>
          <div className="tip">微信扫一扫</div>
        </div>
        <div className="content">
          微信扫一扫与客服经理 <br />
          一对一沟通开店
        </div>
      </Modal>
      <PcContent />
      <Footer />
    </div>
  )
}

export default PcPage;