import React, { useState } from 'react';
import { Modal } from 'antd'
import Footer from '../../../compontents/footer';
import AppContent from './content';
import './index.less';

function AppPage(props) {

  const [isModalVisibles, setIsModalVisibles] = useState(false);



  const handleCancels = () => {
    setIsModalVisibles(false)
  }

  const handleClick = () => {
    setIsModalVisibles(true)
  }

  return (

    <div className="container-app">
      {/* <Header setFlag={props.setFlag} flag={props.flag} setUrl={props.setUrl} url={props.url} /> */}
      <div className="header">
        {/* <div className="top-title">惠租 </div> */}
        <div className="top-content-top">
          租赁小程序
        </div>
        <div className="top-content-center">
          您自己品牌的独立支付宝小程序
        </div>
        <div className="top-content-bottom" id="#lianxi">
          <a href="#lianxi" onClick={handleClick}>立即咨询</a>
        </div>
        <div className="pic-left"></div>
        <div className="pic-right"></div>
      </div>

      {/* <Header setFlag={setFlag} /> */}

      <Modal
        visible={isModalVisibles}
        onCancel={handleCancels}
        footer={null}
        className="modalInquiry"
        getContainer={false}
        width={600}
      >
        <div className="title">立即咨询</div>
        <div className="pic-box">
          <div className="pic"></div>
          <div className="tip">微信扫一扫</div>
        </div>
        <div className="content">
          微信扫一扫与客服经理 <br />
          一对一沟通开店
        </div>
      </Modal>
      <AppContent />
      <Footer />
    </div>
  )
}

export default AppPage;