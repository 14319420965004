import React, { useState, useEffect } from 'react';
import { Modal, Form, Toast, Input, Button } from 'antd-mobile';
import { CloseOutlined } from "@ant-design/icons"
import axios from 'axios'
import './index.less';

function IndexContent(props) {
  const [form] = Form.useForm(); 

  const [leftShow, setLeftShow] = useState("block");
  const [time, setTime] = useState()  //当前时间
  const [tabActiveKey, setTabActiveKey] = useState('1');
  const [leaseShow, setLeaseShow] = useState(false);
  const [newsData, setNewsData] = useState([]);
  const [channel, setChannel] = useState(1);
  const [loading, setLoading] = useState(false);
  const [hasMore, setHasMore] = useState(false);

  useEffect(()=>getNews(0,1),[])

  const getNews = (category,pageNumber) =>{ 
    setLoading(true)
    axios({
      headers: {
        'Content-Type': 'application/json'
      },
      method: 'GET',
      url: `/consumer-zhifubao/news/center?newsCategory=${category}&pageSize=10&pageNumber=${pageNumber}`,
      responseType: 'json',
    }).then((res)=>{
      if(res?.data?.code===1){
        setNewsData(res.data.data.page.records)
        // setCurrent(res.data.data.page.current)
        // setTotal(res.data.data.page.total)
        setLoading(false)
      }
    })
  }

  const loadMore = () => {
    const num = parseInt(newsData.length/10)+1 
    axios({
      headers: {
        'Content-Type': 'application/json'
      },
      method: 'GET',
      url: `/consumer-zhifubao/news/center?newsCategory=${tabActiveKey-1}&pageSize=10&pageNumber=${num}`,
      responseType: 'json',
    }).then((res)=>{
      if(res?.data?.code===1){
        setNewsData(...newsData, ...res.data.data.page.records)
        // setCurrent(res.data.data.page.current)
        // setTotal(res.data.data.page.total)
        // setLoading(false)
        setHasMore(res.data.data.page.records.length > 0)
      }
    })
  }

  const changeLeaseShow = () => {
    setLeaseShow(true)
  }

  const canleLeaseShow = () => {
    setLeaseShow(false)
  }

  const changePages = () => {
    const num = channel+1
    window.open(`${window.location.origin}${window.location.pathname}?flag=${num}`)
    // window.scrollTo(0, 0)
    // props.setFlag(value)
    // // console.log("页面属性"+value)
    // if (value === 2) {
    //   setPage("store")
    //   setValue(2)
    //   // console.log("默认值是2跳转时再复制")
    // } else if (value === 3) {
    //   setPage("app")
    // } else {
    //   setPage("pc")
    // }
  }

  const cancelLeftBox = () => {
    setLeftShow("none");
  }

  const submitForm = (data) => axios({
    headers: {
      'Content-Type': 'application/json'
    },
    method: 'post',
    url: '/consumer-zhifubao/aliPay/user/submitUserConsultInfo',
    responseType: 'json',
    data
  })

  const onFinish = (values) => {
    form.resetFields();
    // console.log("666", values);
    submitForm({
      ...values
    }).then(res => {
      // console.log("666", res)
      if (res?.data?.code === 1) {
        Toast.show({
          icon: 'success',
          content: res.data.msg,
        });
      } else {
        Toast.show({
          icon: 'fail',
          content: res.data.msg,
        });
      }
    });
  };

  //获取当前动态时间
  function getTime() {
    var myDate = new Date();
    var myYear = myDate.getFullYear(); //获取完整的年份(4位,1970-????)
    var myMonth = myDate.getMonth() + 1; //获取当前月份(0-11,0代表1月)
    var myToday = myDate.getDate(); //获取当前日(1-31)
    var myHour = myDate.getHours(); //获取当前小时数(0-23)
    var myMinute = myDate.getMinutes(); //获取当前分钟数(0-59)
    var mySecond = myDate.getSeconds(); //获取当前秒数(0-59)
    var nowTime;

    // nowTime = myYear + '年' + fillZero(myMonth) + '月' + fillZero(myToday) + '日' + '  ' + fillZero(myHour) + ':' +
    //   fillZero(myMinute) + ':' + fillZero(mySecond) + '  ';
    nowTime = `${myYear}年${fillZero(myMonth)}月${fillZero(myToday)}日  ${fillZero(myHour)}:${fillZero(myMinute)}:${fillZero(mySecond)}  `;
    // console.log("时间", nowTime);
    setTime(nowTime)
    // $('#time').html(nowTime);
  };

  function fillZero(str) {
    var realNum;
    if (str < 10) {
      realNum = '0' + str;
    } else {
      realNum = str;
    }
    return realNum;
  }
  setInterval(getTime, 1000);

  const [store, setStore] = useState('1000万+');
  useEffect(()=>{
    let id =  setInterval(()=>{
      setStore(store => store + 1);
    },1000*60*60*24)
    return () =>clearInterval(id)
  },[store])
  
    // window.onload = () => {
    //   const storeContent = document.getElementById("storeContent");
    //   const appContent = document.getElementById("appContent");
    //   const pcContent = document.getElementById("pcContent");
    //   const storeIcon = document.getElementById("storeIcon");
    //   const appIcon = document.getElementById("appIcon");
    //   const pcIcon = document.getElementById("pcIcon")
    //   appIcon.onmouseover = () => {
    //     console.log(1);
    //     appContent.style.display = "block";
    //     storeContent.style.display = "none";
    //     pcContent.style.display = "none";
    //     changeValues1()
    //   }
    //   storeIcon.onmouseover = () => {
    //     console.log(2);
    //     storeContent.style.display = "block";
    //     appContent.style.display = "none";
    //     pcContent.style.display = "none";
    //     changeValues()
    //   }
    //   pcIcon.onmouseover = () => {
    //     console.log(3);
    //     storeContent.style.display = "none";
    //     appContent.style.display = "none";
    //     pcContent.style.display = "block"
    //     changeValues2()
    //   }
    // }

  const productList =  [
    {
      name:'租赁店铺',
      title:'0成本快速开启你的租赁店铺',
      content:[
        '0成本，1分钟免费快速开店',
        '线上，线下，微信，支付宝，等流量全渠道通用',
        '导购选品，免押下单，租金扣款，逾期追款一站式闭环',
        '手机数码/母婴图书/服饰包箱/汽车电动车/美妆个护等全品类通用'
      ]
    },
    {
      name:'租赁小程序',
      title:'你自己的独立品牌',
      content:[
        '低成本享有租物流量红利',
        '基于云服务的支付宝小程序',
        '无需自建研发团队，低成本快速拥有'
      ]
    },
    {
      name:'pc独立站/APP',
      title:'你自己域名的独立站或专属品牌的APP',
      content:[
        '适用于企业租赁场景',
        '无需自建研发团队，低成本快速拥有',
        '你自己域名的独立站或专属品牌的APP' 
      ]
    },
  ]

  const guaranteeList = [
    {
      name: '风控免押金',
      content: '基于神经网络算法\r\n训练的免押金风控模型；\r\n综合逾期率<1%',
      tip: '采用芝麻信用及多头贷，司法数据，社交关联，租赁等共享大数据源，基于神经网络算法训练的免押金风控模型；综合逾期率<1%',
      title: '综合逾期率<1%'
    },
    {
      name: '司法仲裁',
      content: '与仲裁委系统级对接 案件证据一键 提交审理',
      tip: '与仲裁委系统级对接，案件证据一键提交审理，从提交至判决低至一个月时间内；同时可采用法院强制执行，失信人等多维司法途径保障资产安全',
      title: '一键提交审理'
    },
    {
      name: '区块链及保险',
      content: '永久存证，不可篡改；可购买人保，财产损失后保险赔偿',
      tip: '基于蚂蚁区块链的租务证据链取证，留存电子合同与订单以及物流等全流程租物数据；永久存证，不可篡改；可购买人保，财产损失后保险赔偿',
      title: '永久存证，不可篡改'
    },
  ]
  
  return (
    <div className="mobileContent">
      {/* 右侧固定盒子部分 */}
      <div className="fixBox">
        <div className="box-right">
          <div className="top"></div>
          <div className="box">
            <div className="tip">
              惠租客服
            </div>
          </div>
          <div className="lease">
            <div className="pic"></div>
            <div className="font">我要租赁</div>
            <div className="box-lease">
              <div className="title">立即租赁</div>
              <div className="pic"></div>
              <div className="tip">支付宝扫一扫，立即租赁</div>
            </div>
          </div>
          <div className='switch'>
          </div>
          <div className="wechat">
              <div className="pic"></div>
              <div className="font">微信咨询</div>
              <div className="box-wechat">
                <div className="title">微信咨询</div>
                <div className="tip">0成本快速开启你的租赁店铺</div>
                <div className="pic"></div>
                <div className="content">微信扫一扫，立即咨询开店</div>
              </div>
          </div>
          <div className="phone">
              <div className="pic"></div>
              <div className="font">电话咨询</div>
              <div className="box-phone">
                {/* <div className="top1"><span className="big">电话咨询</span>&nbsp; 9:30—18:00</div> */}
                <div className="bottom">客服热线：0571—26881669<br/><span className='small'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;(&nbsp; 9:30—18:00&nbsp;)</span></div>
              </div>
          </div>
        </div>
      </div>
      <div className="mobileContent-container">
        {/* <Header setFlag={props.setFlag} flag={props.flag} setUrl={props.setUrl} url={props.url} /> */}
        <div className="mobileContent-container-header">
          <div className="mobileContent-container-header-title">惠租 </div>
          <div className="mobileContent-container-header-top">
            全品类信用免押金租赁平台
          </div>
          <div className="mobileContent-container-header-center">
            为消费者和商家带来更高效更智能的租赁服务
          </div>
          <div className="mobileContent-container-header-bottom" href="lianxi">
            <a href="#lianxi">商家入驻</a>
          </div>
          <div className="mobileContent-container-header-mount"></div>
        </div>
        <div className='mobileContent-container-pic'></div>
      </div>
      <div className="mobileContent-body">
        {/* 数据展示部分 */}
        <div className="mobileContent-body-data">
          <div className="mobileContent-body-data-time">
            截止于：<span className="mobileContent-body-data-time-upTo">{time}</span>
          </div>
          {/* <hr className="hr" /> */}
          <div className="mobileContent-body-data-datas">
            <div className="mobileContent-body-data-datas-stati" style={{ marginLeft: "0" }}>
              <span className="mobileContent-body-data-datas-stati-span">
                <span className="mobileContent-body-data-datas-stati-span-font">{store}</span>
                <span className="mobileContent-body-data-datas-stati-span-num">用户数</span>
              </span>
            </div>
            <div className="mobileContent-body-data-datas-line"></div>
            <div className="mobileContent-body-data-datas-stati" style={{ marginLeft: "0" }}>
              <span className="mobileContent-body-data-datas-stati-span">
                <span className="mobileContent-body-data-datas-stati-span-font">2000+</span>
                <span className="mobileContent-body-data-datas-stati-span-num">卖家使用惠租</span>
              </span>
            </div>
            <div className="mobileContent-body-data-datas-stati" style={{ marginLeft: "0" }}>
              <span className="mobileContent-body-data-datas-stati-span">
                <span className="mobileContent-body-data-datas-stati-span-font">10万+</span>
                <span className="mobileContent-body-data-datas-stati-span-num">在租商品数</span>
              </span>
            </div>
            <div className="mobileContent-body-data-datas-line"></div>
            <div className="mobileContent-body-data-datas-stati">
              <span className="mobileContent-body-data-datas-stati-span">
                <span className="mobileContent-body-data-datas-stati-span-font">25+</span>
                <span className="mobileContent-body-data-datas-stati-span-num">覆盖省份</span>
              </span>
            </div>
          </div>
        </div>
              {/* 三大核心产品 */}
      <div className="mobileContent-body-product">
        <div className="mobileContent-body-product-title" style={{ cursor: "pointer" }}>
          惠租三大核心产品
        </div>
        <hr className="mobileContent-body-product-hr" />
        <div className="mobileContent-body-product-subtitle">
          一站式、支付、流量、免押金风控、司法，你需要的我们都有
        </div>
        <div className="mobileContent-body-product-icons">
          {productList.map((item,index)=>
          <div className="mobileContent-body-product-icons-box" id="storeIcon" onClick={()=>setChannel(index+1)}>
            <div className="mobileContent-body-product-icons-box-icon"></div>
            <div 
            className={channel===index+1?
              "mobileContent-body-product-icons-box-name-active":
              "mobileContent-body-product-icons-box-name"}
            >
              {item.name}
            </div>
          </div>
          )}
        </div>
        {productList.map((item,index)=>
        <div className="mobileContent-body-product-card" id="storeContent" style={{ display:channel===index+1?"block":"none" }}>
          <span className="mobileContent-body-product-card-title">
            {item.title}
          </span>
          <ul className="mobileContent-body-product-card-contents">
            {item.content.map((i)=>
            <li>
              <div className="mobileContent-body-product-card-contents-dot">
              </div>
              <div className="mobileContent-body-product-card-contents-words">{i}</div>
            </li>
            )}
          </ul>
          <div className="mobileContent-body-product-card-button">
            <div className="mobileContent-body-product-card-button-left">
              <a href="#lianxi">免费试用</a>
            </div>
            <div className="mobileContent-body-product-card-button-right">
              <span onClick={changePages}>查看详情</span>
            </div>
          </div>
          <div className="mobileContent-body-product-card-rightBox">
            <div className="rightStore"></div>
          </div>
        </div>
        )}
      </div>
      {/* 三重保障部分 */}
      <div className="mobileContent-body-guarantee">
        <div className="mobileContent-body-guarantee-title">
          惠租三重保障
        </div>
        <hr className="mobileContent-body-guarantee-hr" />
        <div className="mobileContent-body-guarantee-subtitle">
          全方位为你的资产保驾护航
        </div>
        <div className="mobileContent-body-guarantee-content">
          <div className="mobileContent-body-guarantee-content-scroll">
            {guaranteeList.map((item,index)=>
            <div className="mobileContent-body-guarantee-content-scroll-card">
              {/* 三重保障左边部分 */}
              <div className="mobileContent-body-guarantee-content-scroll-card-inside">
                {/* <div className="box" style={{ height: 36 }}></div> */}
                <div className="mobileContent-body-guarantee-content-scroll-card-inside-icon">
                </div>
                <span className="mobileContent-body-guarantee-content-scroll-card-inside-title">
                  {item.name}
                </span>
                <span className="mobileContent-body-guarantee-content-scroll-card-inside-tip">{item.content}</span>
                <div className="mobileContent-body-guarantee-content-scroll-card-inside-footer">{item.title}</div>
              </div>
              {/* 左边滑入后 */}
              {/* <div className="mobileContent-body-guarantee-content-scroll-card-hover">
                <span className="mobileContent-body-guarantee-content-scroll-card-hover-title">{item.name}</span>
                <span className="mobileContent-body-guarantee-content-scroll-card-hover-tip">
                  {item.tip}
                </span>
                <div className="mobileContent-body-guarantee-content-scroll-card-hover-footer">{item.title}</div>
              </div> */}
            </div>
            )}
          </div>
        </div>
      </div>
      {/* 业务升级部分 */}
      <div className="mobileContent-body-business">
        <div className="mobileContent-body-business-title">
        全行业全场景助你业务升级
        </div>
        <div className="mobileContent-body-business-subtitle">
        只要能租的行业/产品，我们都为你提供解决方案
        </div>
        <div className='mobileContent-body-business-pic'></div>
      </div>
      {/* 消费者业务 */}
      <div className="mobileContent-body-consumption">
        <div className="mobileContent-body-consumption-title">
          惠租消费者业务
        </div>
        <hr className="mobileContent-body-consumption-hr" />
        <div className="mobileContent-body-consumption-subtitle">
          为消费者提供免押金租物服务，让生活更简单
        </div>
        <div className="mobileContent-body-consumption-content">
        </div>
        <div className="mobileContent-body-consumption-button" onClick={changeLeaseShow}>
          我要租赁
        </div>
        <Modal
          visible={leaseShow}
          onClose={canleLeaseShow}
          title='我要租赁'
          footer={null}
          className="mobileContent-body-consumption-modalLease"
          getContainer={false}
          closeOnMaskClick={true}
          //showCloseButton
          content={(
            <>
            {/* <div className="mobileContent-body-consumption-modalLease-title">我要租赁</div> */}
            <div className="mobileContent-body-consumption-modalLease-icon">
            <div className="mobileContent-body-consumption-modalLease-icon-pic"></div>
            <div className="mobileContent-body-consumption-modalLease-icon-title">支付宝扫一扫，立即租赁</div>
          </div>
            </>
          )}
         
        />
      </div>
      {/* 新闻资讯 */}
      <div className='mobileContent-body-news'>
        <div className='mobileContent-body-news-title'>
              新闻资讯
        </div>
        {newsData.map((item,index)=>
        // <div className='mobileContent-body-news-info' onClick={changeURL}>
       <a key={index}  href={item.url} target="_blank" rel="noreferrer">
        <div className='mobileContent-body-news-info' >
          <div className='mobileContent-body-news-info-content'>{item.content}</div>
          <div className='mobileContent-body-news-info-bottom'>
            <div className='mobileContent-body-news-info-bottom-time'>{item.updateTime?item.updateTime.split(' ')[0]:item.createTime.split(' ')[0]}</div>
            <div className='mobileContent-body-news-info-bottom-source'>{item.source}</div>
          </div>
        </div>

      </a>
        )}
      </div>
      {/* 联系我们 */}
      <div className="mobileContent-body-contact" id="lianxi">
        <div className="mobileContent-body-contact-title">
          联系我们
        </div>

        <Form name="basic" onFinish={onFinish} form={form}>
          <Form.Item name="contactName" rules={[{ required: true,message:'您的姓名为必填' }]} style={{ marginTop: 30 }}>
            <div className="mobileContent-body-contact-input">
              <Input type="text" className="mobileContent-body-contact-input-style" placeholder="请输入您的姓名" />
            </div>
          </Form.Item>
          <Form.Item name="enterpriseName" rules={[{ required: true,message:'企业名称为必填'}]}>
            <div className="mobileContent-body-contact-input">
              <Input type="text" className="mobileContent-body-contact-input-style" placeholder="请输入企业名称" />
            </div>
          </Form.Item>
          <Form.Item name="telephone" rules={[{ required: true , message:'手机号码为必填' }]}>
            <div className="mobileContent-body-contact-input">
              <Input type="text" className="mobileContent-body-contact-input-style" placeholder="请输入手机号码" />
            </div>
          </Form.Item>
          <Form.Item name="categories">
            <div className="mobileContent-body-contact-input">
              <Input type="text" className="mobileContent-body-contact-input-style" placeholder="请输入意向类目，如：手机" />
            </div>
          </Form.Item>
          {/* <div className="form-button" onClick={onFinish}>
            提交信息
          </div> */}
          <Button type="submit" className="mobileContent-body-contact-button">提交信息</Button>
        </Form>

        <div className="mobileContent-body-contact-step">
          提交步骤
        </div>
        <div className="mobileContent-body-contact-tag">
          拨打咨询电话，立即领取开店大礼包
        </div>
        <div className="mobileContent-body-contact-pic">
        </div>
        <div className="mobileContent-body-contact-phone">
          <div className="mobileContent-body-contact-phone-icon"></div>
          <span className="mobileContent-body-contact-phone-phoneNumber">0571—26881669</span>
          {/* &nbsp;&nbsp;&nbsp;&nbsp; */}
          <span className="mobileContent-body-contact-phone-phoneNumber">9:30—18:00</span>
        </div>
      </div>
      </div>
      {/* <Footer /> */}
    </div>
  )
}

export default IndexContent;