import React, { useState } from 'react';
// pc端
import Header from './pages/indexContent/header'
import IndexContent from './pages/indexContent';
import StorePage from './pages/otherPage/storePage'
import AppPage from './pages/otherPage/appPage';
import PcPage from './pages/otherPage/pcPage';
import AbboutPage from './pages/otherPage/abboutPage';
import NewsPage from './pages/otherPage/NewsPage/content';
import MobileIndexContent from './pages/mobile/indexContent'
import MobileFooter from './pages/mobile/footer/index'
import MobileHeader from './pages/mobile/header'
import MobileNews from './pages/mobile/news/index'
import MobileAbout from './pages/mobile/aboutUs/index'
import MobileApp from './pages/mobile/theApp/index'
import MobileStore from './pages/mobile/theStore/index'
import MoblilePC from './pages/mobile/thePC/index'



function Index() {

  const [flag, setFlag] = useState(parseInt(window.location.href.split("?flag=")[1])||1)
  const [url, setUrl] = useState('');

  return (
    <div>
      {navigator.userAgent.match(/(iPhone|iPod|Android|ios)/i)?
        (flag === 1 ? (
          // 惠租首页
        <div>
        <MobileHeader/>
        <MobileIndexContent/>
        <MobileFooter/>
        </div>
      ) : (
        flag === 2 ? (
          <div>
            <MobileHeader setFlag={setFlag} flag={flag} setUrl={setUrl} url={url} />
            <MobileStore setFlag={setFlag} />
            <MobileFooter/>
          </div>
        ) : (
          flag === 3 ? (
            <div>
              <MobileHeader setFlag={setFlag} flag={flag} setUrl={setUrl} url={url} />
              <MobileApp setFlag={setFlag} />
              <MobileFooter/>
            </div>
          ) : (
            flag === 4 ? (
              <div>
                <MobileHeader setFlag={setFlag} flag={flag} setUrl={setUrl} url={url} />
                <MoblilePC setFlag={setFlag} />
                <MobileFooter/>
              </div>
            ) : (
              flag === 5 ? (
                <div>
                  <MobileHeader setFlag={setFlag} flag={flag} setUrl={setUrl} url={url} />
                  <MobileNews setFlag={setFlag} setUrl={setUrl} url={url} />
                  <MobileFooter/>
                </div>
                ) : (
                  <div>
                    <MobileHeader setFlag={setFlag} flag={flag} setUrl={setUrl} url={url} />
                    <MobileAbout setFlag={setFlag}/>
                    <MobileFooter/>
                  </div>
              )
            )
          )
        )
      )
      )
      
      :(flag === 1 ? (
        <div>
        <Header setFlag={setFlag} flag={flag} setUrl={setUrl} url={url} />
        <IndexContent setFlag={setFlag} flag={flag} setUrl={setUrl} url={url} />
        </div>
      ) : (
        flag === 2 ? (
          <div>
            <Header setFlag={setFlag} flag={flag} setUrl={setUrl} url={url} />
            <StorePage setFlag={setFlag} />
          </div>
        ) : (
          flag === 3 ? (
            <div>
              <Header setFlag={setFlag} flag={flag} setUrl={setUrl} url={url} />
              <AppPage setFlag={setFlag} />
            </div>
          ) : (
            flag === 4 ? (
              <div>
                <Header setFlag={setFlag} flag={flag} setUrl={setUrl} url={url} />
                <PcPage setFlag={setFlag} />
              </div>
            ) : (
              flag === 5 ? (
                <div>
                  <Header setFlag={setFlag} flag={flag} setUrl={setUrl} url={url} />
                  <NewsPage setFlag={setFlag} setUrl={setUrl} url={url} />
                </div>
                ) : (
                  <div>
                    <Header setFlag={setFlag} flag={flag} setUrl={setUrl} url={url} />
                    <AbboutPage setFlag={setFlag}/>
                  </div>
              )
            )

          )
        )
      )
      )
    }


    </div>
  )
}



export default Index;