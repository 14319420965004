import React,{useState,useEffect} from 'react';
import { Spin , Pagination, Tabs } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';
import axios from 'axios';
import './content.less'
import Footer from '../../../compontents/footer';
const { TabPane } = Tabs;

function NewsContent(props) {

  const [newsData, setNewsData] = useState([]);
  const [tabActiveKey, setTabActiveKey] = useState('1');
  const [current, setCurrent] = useState(1);
  const [total, setTotal] = useState(0);
  const [loading, setLoading] = useState(false);
  
  useEffect(()=>getNews(0,1),[])

  const getNews = (category,pageNumber) =>{ 
    setLoading(true)
    axios({
      headers: {
        'Content-Type': 'application/json'
      },
      method: 'GET',
      url: `/consumer-zhifubao/news/center?newsCategory=${category}&pageSize=3&pageNumber=${pageNumber}`,
      responseType: 'json',
    }).then((res)=>{
      if(res?.data?.code===1){
        setNewsData(res.data.data.page.records)
        setCurrent(res.data.data.page.current)
        setTotal(res.data.data.page.total)
        setLoading(false)
      }
    })
  }

  const pageChange=num=>{
    getNews(tabActiveKey-1,num)
  }
  const onTabChange=key=>{
    setTabActiveKey(key)
    getNews(key-1,1)
  }

  const antIcon = <LoadingOutlined style={{ fontSize: 48, color:'#EB3A32' }} spin />;

  return (
    <div className='news'>
      <div className='newsBanner'>
        {/* <div className='center'> */}
          <div className='place'></div>
          <div className='title'>新闻中心</div>
          <div className='tip'>Press Center</div>
        {/* </div> */}
      </div>
      <Tabs 
      className='newsTabs'
      // defaultActiveKey="1"
      activeKey={tabActiveKey}
      onChange={onTabChange}>
        <TabPane className='newsTabsPane' tab='行业动态' key='1'>
        </TabPane>
        <TabPane tab='公司新闻' key='2'>
          </TabPane>
        <TabPane tab='商家案例' key='3'>
          </TabPane>
        <TabPane tab='媒体报道' key='4'>
        </TabPane>
      </Tabs>
      {/* <div className='iframeContent'>
        <Button className='iframeContentBtn' onClick={()=>props.setUrl('')} size="large">返回</Button>
        <iframe src={props.url}></iframe>
      </div> */}
      <Spin spinning={loading} indicator={antIcon}>
      {newsData.map((item,index)=>
        <a key={index} className='newsContent' href={item.url} target="_blank" rel="noreferrer">
          <div className='newsImg'><img src={item.pictureUrl} alt=''/></div>
          <div className='newsInfo'>
            <h6 className='newsTitle'>{item.title}</h6>
            <div className='newsIndex'>{item.content}</div>
            {/* <div className='newsDate'>{item?.updateTime?item.updateTime:item.createTime}</div> */}
            <div className='newsDate'>{item?.updateTime?item.updateTime.split(' ')[0]:item.createTime.split(' ')[0]}</div>
            <div className='newsSource'>{item.source}</div>
          </div>
        </a>
        )}
      </Spin>
        <Pagination className='newsPage' current={current} total={total} pageSize={3} onChange={pageChange}></Pagination>
        <Footer />
    </div>
  )
}

export default NewsContent;