import React, { useState } from 'react';
import { Modal, Input, Form, Button, message } from 'antd';
import axios from 'axios'
// import Footer from '../../../compontents/footer';
import './index.less';

function About(props) {

  const [form] = Form.useForm();

  const [isModalShow, setIsModalShow] = useState(false);

  const submit = (data) => axios({
    headers: {
      'Content-Type': 'application/json'
    },
    method: 'post',
    url: '/consumer-zhifubao/aliPay/user/submitUserConsultInfo',
    responseType: 'json',
    data
  })

  const onFinishs = (values) => {
    form.resetFields();
    // console.log("666", values);
    submit({
      ...values
    }).then(res => {
      // console.log("666", res)
      if (res?.data?.code === 1) {
        setIsModalShow(false)
        message.success(res.data.msg);
      } else {
        message.error(res.data.msg);
      }
    });
  };

  const cancelModal = () => {
    setIsModalShow(false)
  }

  const showModal = () => {
    setIsModalShow(true)
  }

  const management = [
      {
           name:'李昱龙 ｜ 创始人CEO',
           resume: '个人简介：原支付宝交易线产品经理；电子科技大学硕士，有金融学和电子工程双学位。'
      },
      {
           name:'杨代明 ｜ 联合创始人',
           resume: '个人简介：原百度产品专家；电子科技大学硕士，有工商管理和电子工程双学位。'
      },
      {
           name:'王国丰 ｜ 研发总监',
           resume: '个人简介：原滴滴架构师，魔筷科技交易线技术负责人，郑州大学学士。'
      },
      {
          name:'周日清 ｜ 合伙人',
          resume: '个人简介：原阿里云、淘宝高级专家，阿里6年陈，深耕Martech领域多年；毕业于四川大学。'
      },
  ]

  return (
    <div className="about">
      <div className="about-header">
        <div className="about-header-title">让租赁更放心更简单</div>
      </div>
      <div className="about-title">
        关于我们
      </div>
      <div className="about-tip">
        COMPANY PROFILE
      </div>
      <div className="about-pic">
      </div>
      <div className="about-describe">
        <div className="about-describe-title">
          公司简介  <div className="about-describe-borderLine"></div>
        </div>
        <div className="about-describe-content">
              <div className="text">
                &emsp;&emsp;脚本科技成立于2019年，创始团队主要来自阿里巴巴，百度，美团等，公司已获得梦想小镇优质项目，人工智能小镇，国科小，省科小，杭州市雏鹰计划等荣誉认证。 惠租是一个消费电子信用租赁网站及数字化租赁交易技术服务提供商。惠租旗下有两款产品，惠租平台和惠租云。
              </div>
              <div className="text">
                &emsp;&emsp;惠租平台整合租赁行业的商家入驻，包括手机数码、游戏电玩、企业办公等为主的商家入驻，另外还整合传统行业如零售门店、家居家电、电动车、自助设备、母婴图书等商家入驻，此外还有与阿里巴巴合作的支付宝生态信用免押租。
              </div>
              <div className="text">
                &emsp;&emsp;惠租云SaaS系统是一款免押金租赁支付宝小程序SaaS版，主要产品形态有租赁店铺、租赁小程序和PC独立站，为以上类目的租赁商家提供SaaS租赁系统，商家可以使用该小程序提高企业在支付宝渠道的运营效率。
              </div>
              <div className="text">
                &emsp;&emsp;在惠租平台开店非常简单，0成本、1分钟就能开通惠租的租赁店铺，而且开通租赁店铺不需要任何费用。惠租平台与商家采用CPS的形式进行合作，也就是说，商家不需要沉重的运营成本就能够在惠租平台轻松完成交易。
              </div>
        </div>
      </div>
      <div className="about-team">
        <div className="about-team-title">
          高管团队
        </div>
        <div className="about-team-tip">
          SENIOR MANAGEMENT TEAM
        </div>
        {management.map((item)=>
        <div className='about-team-content'>
          <div className='about-team-content-left'></div>
          <div className='about-team-content-right'>
            <div className='about-team-content-right-name'>
                {item.name}
            </div>
            <div className='about-team-content-right-resume'>
                {item.resume}
            </div>
          </div>
        </div>
        )}
      </div>
      <div className="about-trial">
          <div className="about-trial-title">一分钟免费拥有你的租赁店铺</div>
          <div className="about-trial-button" onClick={showModal}>免费试用</div>
          <div className='about-trial-pic-r'></div>
          {/* <div className='about-trial-pic-l'></div> */}
      </div>
      <div className="about-slogan">
        <div className="about-slogan-title">
          我们努力践行的文化
        </div>
        <div className="about-slogan-content">
          <div className="about-slogan-content-item">
            <div className="about-slogan-content-item-title">使命</div>
            <div className="about-slogan-content-item-content">促进生活和商业进步</div>
          </div>
          <div className="about-slogan-content-item">
            <div className="about-slogan-content-item-title">愿景</div>
            <div className="about-slogan-content-item-content">以探索创新创造价值的企业</div>
          </div>
          <div className="about-slogan-content-item">
            <div className="about-slogan-content-item-title">价值观</div>
            <div className="about-slogan-content-item-content">客户第一，诚信正直，勇于探索</div>
          </div>
        </div>
      </div>
      <Modal
        visible={isModalShow}
        onCancel={cancelModal}
        footer={null}
        className="modalAppointment"
       // title="立即预约"
        getContainer={false}
        width={570}
      >
        <Form name="basic" layout="horizontal" onFinish={onFinishs} form={form} >
          <div className="title">立即预约</div>
          <div className="form">
            <div className="form-div" style={{ marginTop: 20 }}>
              <span className="span" style={{ paddingRight: 5.97 }}>姓<span style={{ visibility: "hidden" }}>姓名</span>名：</span>
              <Form.Item name="contactName" rules={[{ required: true, message: '您的姓名为必填' }]}>
                <div className="inputs" style={{ marginLeft: -5.97 }}>
                  <Input type="text" placeholder="请输入您的姓名" className="input" />
                </div>
              </Form.Item>
            </div>
            <div className="form-div">
              <span className="span">企业名称：</span>
              <Form.Item name="enterpriseName" rules={[{ required: true, message: '企业名称为必填' }]}>
                <div className="inputs">
                  <Input type="text" placeholder="请输入企业名称" className="input" />
                </div>
              </Form.Item>
            </div>
            <div className="form-div">
              <span className="span">手机号码：</span>
              <Form.Item name="telephone" rules={[{ required: true, message: '手机号码为必填' }]}>
                <div className="inputs">
                  <Input type="text" placeholder="请输入手机号码" className="input" />
                </div>
              </Form.Item>
            </div>
            <div className="form-div">
              <span className="span">意向类目：</span>
              <Form.Item name="categories">
                <div className="inputs">
                  <Input type="text" placeholder="请输入意向类目，如：手机" className="input" />
                </div>
              </Form.Item>
            </div>
          </div>
          <div className="buttonBox">
            <Button className="button" htmlType="submit"  size='large'>提交信息</Button>
          </div>
        </Form>
      </Modal>
      {/* <Footer /> */}
    </div>
  )
}

export default About;