import React from 'react';
// import { Divider, } from 'antd';
import { Popup, Collapse, Space, Button } from 'antd-mobile'
import {
    AddOutline,
    MinusOutline,
  } from 'antd-mobile-icons'
import './index.less'

function Footer() {
  return(
    <div className="bottom">                    
        <Collapse 
        className='bottom-fold' 
        // accordion
        arrow={active => (active ? <MinusOutline /> : <AddOutline />)}
        // defaultActiveKey={['1']}
        >
            <Collapse.Panel key='1' title='C端服务'>
                <div className="bottom-fold-row">
                    <div className="bottom-fold-row-content">手机租赁</div>
                    <div className="bottom-fold-row-content">电脑租赁</div>
                    <div className="bottom-fold-row-content">数码租赁</div>
                    <div className="bottom-fold-row-content">企业租赁</div>
                </div>
            </Collapse.Panel>
            <Collapse.Panel key='2' title='B端服务(云服务)'>
                <div className="bottom-fold-row">
                    <div className="bottom-fold-row-content">微信私域</div>
                    <div className="bottom-fold-row-content">零售门店</div>
                    <div className="bottom-fold-row-content">手机数码</div>
                    <div className="bottom-fold-row-content">企业办公</div>
                </div>
            </Collapse.Panel>
            <Collapse.Panel key='3' title='服务号二维码'>
                <div className="bottom-fold-row">
                    <div className="bottom-fold-row-box">
                        <div className="bottom-fold-row-box-iconLeft"></div>
                        <div className="bottom-fold-row-box-tip">客户顾问</div>
                    </div>
                    <div className="bottom-fold-row-box">
                        <div className="bottom-fold-row-box-iconCenter"></div>
                        <div className="bottom-fold-row-box-tip">微信公众号</div>
                    </div>
                    <div className="bottom-fold-row-box">
                        <div className="bottom-fold-row-box-iconRight"></div>
                        <div className="bottom-fold-row-box-tip">支付宝生活号</div>
                    </div>
                </div>
            </Collapse.Panel>
            <Collapse.Panel key='4' title='联系我们'>
                
            <div className="bottom-fold-info bottom-fold-first">
              <div className='bottom-fold-info-left'>客服电话：</div>
              <div className='bottom-fold-info-right'>0571—26881669</div>
            </div>
            <div className="bottom-fold-info">
              <div className='bottom-fold-info-left'>人工服务：</div>
              <div className='bottom-fold-info-right'>9:30—18:00</div>
            </div>
            <div className="bottom-fold-info">
              <div className='bottom-fold-info-left'>公司名称：</div>
              <div className='bottom-fold-info-right'>杭州脚本信息技术有限公司</div>
            </div>
            <div className="bottom-fold-info">
              <div className='bottom-fold-info-left'>公司地址：</div>
              <div className='bottom-fold-info-right'>浙江省杭州市余杭区仓前街道文一西路1378号E幢6楼</div>
              </div>
            <div className="bottom-fold-info">
              <div className='bottom-fold-info-left'>商务/渠道及其它合作：</div>
              <div className='bottom-fold-info-right'>business@huizustore.com</div>
              </div>
            </Collapse.Panel>
        </Collapse>
      <div className="bottom-hr" />
        <div className="bottom-foot">
            <div className="bottom-foot-center">备案/许可证号：<a href="https://beian.miit.gov.cn" rel="noreferrer" target="_blank">浙ICP备19009847号</a></div>
            <div className="bottom-foot-center">@2022 杭州脚本信息技术有限公司 版权所有</div>
            <div className="bottom-foot-center">Copyright © 惠租电信业务经营许可证：浙B2-20190991</div>
        </div>
    </div>
  )
}

export default Footer;