import React, { useState, useEffect } from 'react';
import { Modal, Form, Toast, Input, Button } from 'antd-mobile';
//import { CloseOutlined } from "@ant-design/icons"
import axios from 'axios'
import './index.less';

function Pc(props) {
  const [isModalVisible, setIsModalVisible] = useState(false);

  const handleCancel = () => {
    setIsModalVisible(false)
  }

  const handleClick = () => {
    setIsModalVisible(true)
  }
  const iconList = ['招商店铺入驻','商品管理','交易管理','风控管理','租后管理','财务运算']
  //const [form] = Form.useForm(); 

  // const productList = [
  //   {
  //     name:'租赁店铺',
  //     title:'0成本快速开启你的租赁店铺',
  //     content:[
  //       '0成本，1分钟免费快速开店',
  //       '线上，线下，微信，支付宝，等流量全渠道通用',
  //       '导购选品，免押下单，租金扣款，逾期追款一站式闭环',
  //       '手机数码/母婴图书/服饰包箱/汽车电动车/美妆个护等全品类通用'
  //     ]
  //   },
  //   {
  //     name:'租赁店铺',
  //     title:'你自己域名的独立站或专属品牌的APP',
  //     content:[
  //       '你自己域名的独立站或专属品牌的APP',
  //       '适用于企业租赁场景',
  //       '无需自建研发团队，低成本快速拥有'
  //     ]
  //   },
  //   {
  //     name:'租赁店铺',
  //     title:'你自己的独立品牌',
  //     content:[
  //       '基于云服务的支付宝小程序',
  //       '低成本享有租物流量红利',
  //       '无需自建研发团队，低成本快速拥有',
  //     ]
  //   },
  // ]

  // const guaranteeList = [
  //   {
  //     name: '风控免押金',
  //     content: '基于神经网络算法\r\n训练的免押金风控模型；\r\n综合逾期率<1%',
  //     tip: '采用芝麻信用及多头贷，司法数据，社交关联，租赁等共享大数据源，基于神经网络算法训练的免押金风控模型；综合逾期率<1%',
  //     title: '综合逾期率<1%'
  //   },
  //   {
  //     name: '司法仲裁',
  //     content: '与仲裁委系统级对接 案件证据一键 提交审理',
  //     tip: '与仲裁委系统级对接，案件证据一键提交审理，从提交至判决低至一个月时间内；同时可采用法院强制执行，失信人等多维司法途径保障资产安全',
  //     title: '一键提交审理'
  //   },
  //   {
  //     name: '区块链及保险',
  //     content: '永久存证，不可篡改；可购买人保，财产损失后保险赔偿',
  //     tip: '基于蚂蚁区块链的租务证据链取证，留存电子合同与订单以及物流等全流程租物数据；永久存证，不可篡改；可购买人保，财产损失后保险赔偿',
  //     title: '永久存证，不可篡改'
  //   },
  // ]
  
  return (
    <div className="pc">
      <div className="pc-container">
        {/* <Header setFlag={props.setFlag} flag={props.flag} setUrl={props.setUrl} url={props.url} /> */}
        <div className="pc-container-header">
          <div className="pc-container-header-title">PC独立站/APP </div>
          <div className="pc-container-header-top" >
            建立您自己的租赁网站  <br/>APP其实很简单
          </div>
          <div className="pc-container-header-bottom" onClick={handleClick}>
           立即咨询
          </div>
          {/* <div className="pc-container-header-bottom" href="lianxi">
            <a href="#lianxi">商家入驻</a>
          </div> */}
          <div className="pc-container-header-mount"></div>
        </div>
        <div className='pc-container-pic'></div>
      </div>
      <div className="pc-body">
        <div className="pc-body-first">
            <div className="pc-body-title">PC独立站</div>
            <div className="pc-body-tip">适用于面向B端客户对企业租赁场景</div>
            <Button onClick={handleClick}>立即咨询</Button>
            <div className="pc-body-first-icon"></div>
        </div>
        <div className="pc-body-second">
            <div className="pc-body-title">快速建立你的APP</div>
            <div className="pc-body-tip">包含安卓和IOS版本，快速上架应用商场<br/>适用于各类租赁场景</div>
            <Button onClick={handleClick} >立即咨询</Button>
            <div className="pc-body-second-icon"></div>
        </div>
        <div className="pc-body-title">完美的一站式租赁流程</div>
        <div className="pc-body-third">
            {iconList.map((item)=>
              <div className='pc-body-third-icons'>
                  <div className='pc-body-third-icons-icon'></div>
                  <div className='pc-body-third-icons-tip'>{item}</div>
              </div>
              )}
        </div>
      </div>
      <div className="pc-service">
        <div className="pc-service-title">马上开始搭建您的线上店铺</div>
        <div className="pc-service-button"><Button onClick={handleClick}>立即咨询</Button></div>
      </div>
      
      <Modal
        visible={isModalVisible}
        //onCancel={handleCancel}
        footer={null}
        className="modalInquiry"
        getContainer={false}
        width={600}
        showCloseButton
        onClose={handleCancel}
        title='立即咨询'
        content={(
          <>
          <div className="pic-box">
            <div className="pic"></div>
            <div className="tip">微信扫一扫</div>
          </div>
          <div className="content">
            微信扫一扫与客服经理 <br />
            一对一沟通开店
          </div>
          </>
        )}
      />
      {/* <Footer /> */}
    </div>
  )
}

export default Pc;