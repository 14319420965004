import React, { useState } from 'react';
import { Modal, Input, message, Form, Button } from 'antd'
import axios from 'axios';
import Footer from '../../../compontents/footer';
import StoreContent from './content';
import './index.less';

function StorePage(props) {

  const [form] = Form.useForm();

  const [isModalShow, setIsModalShow] = useState(false);

  const submit = (data) => axios({
    headers: {
      'Content-Type': 'application/json'
    },
    method: 'post',
    url: '/consumer-zhifubao/aliPay/user/submitUserConsultInfo',
    responseType: 'json',
    data
  })

  const onFinishs = (values) => {
    form.resetFields();
    // console.log("666", values);
    submit({
      ...values
    }).then(res => {
      // console.log("666", res)
      if (res?.data?.code === 1) {
        setIsModalShow(false)
        message.success(res.data.msg);
      } else {
        message.error(res.data.msg);
      }
    });
  };

  const cancelModal = () => {
    setIsModalShow(false)
  }

  const showModal = () => {
    setIsModalShow(true)
  }

  return (

    <div className="container-store1">
      <div className="header">
        <div className="top-content-top">
          租赁店铺
        </div>
        <div className="top-content-center">
          一分钟免费拥有自己的租赁店铺
        </div>
        <div className="top-content-bottom" id="#lianxi">
          <a href="#lianxi" onClick={showModal}>立即试用</a>
        </div>
        <div className="pic-left"></div>
        <div className="pic-right"></div>
      </div>
      <Modal
        visible={isModalShow}
        onCancel={cancelModal}
        footer={null}
        className="modalAppointment"
        getContainer={false}
        width={570}
      >
        <Form name="basic" onFinish={onFinishs} form={form}>
          <div className="title">立即预约</div>
          <div className="form">
            <div className="form-div" style={{ marginTop: 20 }}>
              <span className="span" style={{ paddingRight: 5.97 }}>姓<span style={{ visibility: "hidden" }}>姓名</span>名：</span>
              <Form.Item name="contactName" rules={[{ required: true, message: '您的姓名为必填' }]}>
                <div className="inputs" style={{ marginLeft: -5.97 }}>
                  <Input type="text" placeholder="请输入您的姓名" className="input" />
                </div>
              </Form.Item>
            </div>
            <div className="form-div">
              <span className="span">企业名称：</span>
              <Form.Item name="enterpriseName" rules={[{ required: true, message: '企业名称为必填' }]}>
                <div className="inputs">
                  <Input type="text" placeholder="请输入企业名称" className="input" />
                </div>
              </Form.Item>
            </div>
            <div className="form-div">
              <span className="span">手机号码：</span>
              <Form.Item name="telephone" rules={[{ required: true, message: '手机号码为必填' }]}>
                <div className="inputs">
                  <Input type="text" placeholder="请输入手机号码" className="input" />
                </div>
              </Form.Item>
            </div>
            <div className="form-div">
              <span className="span">意向类目：</span>
              <Form.Item name="categories">
                <div className="inputs">
                  <Input type="text" placeholder="请输入意向类目，如：手机" className="input" />
                </div>
              </Form.Item>
            </div>
            <div className="buttonBox">
              <Button className="button" htmlType="submit">提交信息</Button>
            </div>
          </div>
        </Form>
      </Modal>
      <StoreContent />
      <Footer />
    </div>
  )
}

export default StorePage;