import React, { useState, useEffect } from 'react';
import { Modal, Form, Toast, Input, Button } from 'antd-mobile';
// import { CloseOutlined } from "@ant-design/icons"
import axios from 'axios'
import './index.less';

function App(props) {

  //const [form] = Form.useForm(); 
  const [isModalVisible, setIsModalVisible] = useState(false);

  const handleCancel = () => {
    setIsModalVisible(false)
  }

  const handleClick = () => {
    setIsModalVisible(true)
  }


  const appList = [
    {
      name:'流量红利',
      title:'支付宝租物流量红利，新消费新人群',
    },
    {
      name:'低成本',
      title:'低成本建立您自己品牌的应用，运营维护更简单',
    },
    {
      name:'服务指导',
      title:'VIP 1对1 专属运营指导，助您业务增长',
    },
    {
      name:'多渠道',
      title:'包含微信H5和支付宝小程序，线上线下全渠备份',
    },
  ]

  const iconList = ['招商店铺入驻','商品管理','订单管理','资金结算','渠道分销','免押金风控','区块链保险','司法仲裁','租金代扣','在线客服']

  const guaranteeList = [
    {
      name: '风控免押金',
      content: '基于神经网络算法\r\n训练的免押金风控模型；\r\n综合逾期率<1%',
      tip: '采用芝麻信用及多头贷，司法数据，社交关联，租赁等共享大数据源，基于神经网络算法训练的免押金风控模型；综合逾期率<1%',
      title: '综合逾期率<1%'
    },
    {
      name: '司法仲裁',
      content: '与仲裁委系统级对接 案件证据一键 提交审理',
      tip: '与仲裁委系统级对接word-wrap，案件证据一键提交审理，从提交至判决低至一个月时间内；同时可采用法院强制执行，失信人等多维司法途径保障资产安全',
      title: '一键提交审理'
    },
    {
      name: '区块链及保险',
      content: '永久存证，不可篡改；可购买人保，财产损失后保险赔偿',
      tip: '基于蚂蚁区块链的租务证据链取证，留存电子合同与订单以及物流等全流程租物数据；永久存证，不可篡改；可购买人保，财产损失后保险赔偿',
      title: '永久存证，不可篡改'
    },
  ]
  const storeiconList=[
    {
      title:"兔巴租机",
      content:"Bieber建的全品类店铺",
    },
    {
      title:"大叔数码",
      content:"全品类店铺",
    },
    {
      title:"熊猫绘本",
      content:"主营儿童绘本租赁服务",
    },
    {
      title:"租元璋",
      content:"电脑租赁",
    },
    {
      title:"恒乐嘉手机",
      content:"专业手机租赁",
    },
  ]
  
  return (
    <div className="app">
      <div className="app-container">
        {/* <Header setFlag={props.setFlag} flag={props.flag} setUrl={props.setUrl} url={props.url} /> */}
        <div className="app-container-header">
          <div className="app-container-header-title">惠租小程序</div>
          <div className="app-container-header-top">
            您自己品牌的独立支付宝小程序
          </div>
          <div className="app-container-header-bottom" onClick={handleClick}> 
           立即咨询
          </div>
          {/* <div className="app-container-header-bottom" href="lianxi">
            <a href="#lianxi">立即咨询</a>
          </div> */}
          <div className="app-container-header-mount"></div>
        </div>
        <div className='app-container-pic'></div>
      </div>
      <div className='app-body'>
          <div className='app-body-title' style={{marginTop:'42px'}}>
              <div>丰富的功能</div>
              <div>适用于不同的租赁场景和需求</div>
          </div>
          <div className='app-body-subtitle'>
          一站式闭环交易能力，金融级的租物交易能力
          </div>
          <div className='app-body-scene'>
              {iconList.map((item)=>
              <div className='app-body-scene-icons'>
                  <div className='app-body-scene-icons-icon'></div>
                  <div className='app-body-scene-icons-tip'>{item}</div>
              </div>
              )}
          </div>
          <div className='app-body-title'>
          租赁支付宝小程序
          </div>
          <div className='app-body-subtitle'>
          独立品牌，私域流量，让经营变得更简单
          </div>
          {appList.map((item,index)=>
          <div className='app-body-program' style={index?null:{marginTop:'5px'}}>
              <div className='app-body-program-secondary'>
                  {item.name}
              </div>
              <div className='app-body-program-describe'>
                    {item.title}
              </div>
          </div>
          )}
          <div className='app-body-button' onClick={handleClick}>
              立即咨询
          </div>
          <div className='app-body-title'>
          为你提供线上运营全链路解决方案
          </div>
          <div className='app-body-subtitle'>
          多种业务模式均可支持
          </div>
          <div className='app-body-models'>
              <div className='app-body-models-inside'>
                <div className='app-body-models-inside-mode'>
                    <div className='app-body-models-inside-mode-mask'>
                        <div className='app-body-models-inside-mode-mask-title'>自营模式</div>
                        <div className='app-body-models-inside-mode-mask-tip'>品类自营，出租你的商品，获得长期租金收益</div>
                    </div>
                </div>
                <div className='app-body-models-inside-mode'>
                    <div className='app-body-models-inside-mode-mask'>
                        <div className='app-body-models-inside-mode-mask-title'>平台模式</div>
                        <div className='app-body-models-inside-mode-mask-tip'>招募商家入驻你的小程序，助你快速扩充业务品类，带来用户增长</div>
                    </div>
                </div>
                <div className='app-body-models-inside-mode'>
                    <div className='app-body-models-inside-mode-mask'>
                        <div className='app-body-models-inside-mode-mask-title'>分销模式</div>
                        <div className='app-body-models-inside-mode-mask-tip'>支持招募线上及线下渠道代理，让线上渠道及线下门店，为你推广商品，获取佣金收益，支持多级分销</div>
                    </div>
                </div>
              </div>
          </div>
          {/* <div className='app-body-title'>
          四步快速开店
          </div> */}
          <div className='app-body-step'><div className="fourSteps" /></div>
          <div className='app-body-title'>
          他们都在用惠租
          </div>
          <div className='app-body-subtitle'>
          排名不分先后顺序
          </div>
          <div className="icon-box">
            <div className="zhezhaoceng"></div>
            <div className="store-icons">
            {storeiconList.map((item)=>
                <div className="store-icon">
                  <div className="icon">
                      <div className="icon-bk"></div>
                      <div className="icon-head"></div>
                      <div className="icon-title">{item.title}</div>
                      <div className="icon-content">{item.content}</div>
                  </div>
                </div>
            )}
           </div>
          </div>
      </div>
      <div className="app-service">
        <div className="app-service-title">马上开始搭建您的租赁小程序</div>
        <div className="app-service-button"><Button onClick={handleClick}>立即咨询</Button></div>
      </div>
      
      <Modal
        visible={isModalVisible}
        //onCancel={handleCancel}
        footer={null}
        className="modalInquiry"
        getContainer={false}
        width={600}
        showCloseButton
        onClose={handleCancel}
        title='立即咨询'
        content={(
          <>
          <div className="pic-box">
            <div className="pic"></div>
            <div className="tip">微信扫一扫</div>
          </div>
          <div className="content">
            微信扫一扫与客服经理 <br />
            一对一沟通开店
          </div>
          </>
        )}
      />

        {/* <div className="title">立即咨询</div>
        <div className="pic-box">
          <div className="pic"></div>
          <div className="tip">微信扫一扫</div>
        </div>
        <div className="content">
          微信扫一扫与客服经理 <br />
          一对一沟通开店
        </div> */}
      {/* </Modal> */}
      {/* <Footer/>  */}
    </div>

  )
}

export default App;