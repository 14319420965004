import React, { useState, useEffect } from 'react';
import { Modal, Form, Input, Button, message } from 'antd';
import { CloseOutlined } from "@ant-design/icons"
import axios from 'axios'
import Footer from '../../compontents/footer';
import './index.less';

function IndexContent(props) {

  const [form] = Form.useForm(); 

  const [leftShow, setLeftShow] = useState("block");
  const [time, setTime] = useState()  //当前时间
  const [leaseShow, setLeaseShow] = useState(false);
  const [newsData, setNewsData] = useState([]);
  const [channel, setChannel] = useState(1);

  useEffect(()=>{
    getNews()
    console.log(navigator.userAgent.match(/(iPhone|iPod|Android|ios)/i));
  },[])
  

  const getNews = (category,pageSize) => axios({
    headers: {
      'Content-Type': 'application/json'
    },
    method: 'GET',
    url: `/consumer-zhifubao/news/center?newsCategory=0&pageSize=10&pageNumber=1`,
    responseType: 'json',
  }).then((res)=>{
    if(res?.data?.code===1){
      setNewsData(res.data.data.opeNews)
    }
  })

  const changeLeaseShow = () => {
    setLeaseShow(true)
  }

  const canleLeaseShow = () => {
    setLeaseShow(false)
  }

  const changePages = () => {
    const num = channel+1
    window.open(`${window.location.origin}${window.location.pathname}?flag=${num}`)
    // window.scrollTo(0, 0)
    // props.setFlag(value)
    // // console.log("页面属性"+value)
    // if (value === 2) {
    //   setPage("store")
    //   setValue(2)
    //   // console.log("默认值是2跳转时再复制")
    // } else if (value === 3) {
    //   setPage("app")
    // } else {
    //   setPage("pc")
    // }
  }

  const cancelLeftBox = () => {
    setLeftShow("none");
  }

  const submitForm = (data) => axios({
    headers: {
      'Content-Type': 'application/json'
    },
    method: 'post',
    url: '/consumer-zhifubao/aliPay/user/submitUserConsultInfo',
    responseType: 'json',
    data
  })

  const onFinish = (values) => {
    form.resetFields();
    // console.log("666", values);
    submitForm({
      ...values
    }).then(res => {
      // console.log("666", res)
      if (res?.data?.code === 1) {
        message.success(res.data.msg);
      } else {
        message.error(res.data.msg);
      }
    });
  };

  //获取当前动态时间
  function getTime() {
    var myDate = new Date();
    var myYear = myDate.getFullYear(); //获取完整的年份(4位,1970-????)
    var myMonth = myDate.getMonth() + 1; //获取当前月份(0-11,0代表1月)
    var myToday = myDate.getDate(); //获取当前日(1-31)
    var myHour = myDate.getHours(); //获取当前小时数(0-23)
    var myMinute = myDate.getMinutes(); //获取当前分钟数(0-59)
    var mySecond = myDate.getSeconds(); //获取当前秒数(0-59)
    var nowTime;

    // nowTime = myYear + '年' + fillZero(myMonth) + '月' + fillZero(myToday) + '日' + '  ' + fillZero(myHour) + ':' +
    //   fillZero(myMinute) + ':' + fillZero(mySecond) + '  ';
    nowTime = `${myYear}年${fillZero(myMonth)}月${fillZero(myToday)}日  ${fillZero(myHour)}:${fillZero(myMinute)}:${fillZero(mySecond)}  `;
    // console.log("时间", nowTime);
    setTime(nowTime)
    // $('#time').html(nowTime);
  };

  function fillZero(str) {
    var realNum;
    if (str < 10) {
      realNum = '0' + str;
    } else {
      realNum = str;
    }
    return realNum;
  }
  setInterval(getTime, 1000);

  const [store, setStore] = useState('1000万+');
  useEffect(()=>{
    let id =  setInterval(()=>{
      setStore(store => store + 1);
    },1000*60*60*24)
    return () =>clearInterval(id)
  },[store])
  
    // window.onload = () => {
    //   const storeContent = document.getElementById("storeContent");
    //   const appContent = document.getElementById("appContent");
    //   const pcContent = document.getElementById("pcContent");
    //   const storeIcon = document.getElementById("storeIcon");
    //   const appIcon = document.getElementById("appIcon");
    //   const pcIcon = document.getElementById("pcIcon")
    //   appIcon.onmouseover = () => {
    //     console.log(1);
    //     appContent.style.display = "block";
    //     storeContent.style.display = "none";
    //     pcContent.style.display = "none";
    //     changeValues1()
    //   }
    //   storeIcon.onmouseover = () => {
    //     console.log(2);
    //     storeContent.style.display = "block";
    //     appContent.style.display = "none";
    //     pcContent.style.display = "none";
    //     changeValues()
    //   }
    //   pcIcon.onmouseover = () => {
    //     console.log(3);
    //     storeContent.style.display = "none";
    //     appContent.style.display = "none";
    //     pcContent.style.display = "block"
    //     changeValues2()
    //   }
    // }
  
  return (
    <div className="indexContent">
      {/* 左侧固定盒子部分 */}
      <div className="leftBox" style={{display:leftShow}}>
        <div className="icon"><CloseOutlined  onClick={cancelLeftBox} style={{cursor:"pointer"}} /></div>
        <div className="title">立即租赁</div>
        <div className="pic"></div>
        <div className="tip">支付宝扫一扫，立即租赁</div>
      </div>
      {/* 右侧固定盒子部分 */}
      <div className="fixBox">
        <div className="box-right">
          <div className="top"></div>
          <div className="box">
            <div className="tip">
              惠租客服
            </div>
          </div>
          <div className="lease">
            <div className="pic"></div>
            <div className="font">我要租赁</div>
            <div className="box-lease">
              <div className="title">立即租赁</div>
              <div className="pic"></div>
              <div className="tip">支付宝扫一扫，立即租赁</div>
            </div>
          </div>
          <div className='switch'>
          </div>
          <div className="wechat">
              <div className="pic"></div>
              <div className="font">微信咨询</div>
              <div className="box-wechat">
                <div className="title">微信咨询</div>
                <div className="tip">0成本快速开启你的租赁店铺</div>
                <div className="pic"></div>
                <div className="content">微信扫一扫，立即咨询开店</div>
              </div>
          </div>
          <div className="phone">
              <div className="pic"></div>
              <div className="font">电话咨询</div>
              <div className="box-phone">
                {/* <div className="top1"><span className="big">电话咨询</span>&nbsp; 9:30—18:00</div> */}
                <div className="bottom">客服热线：0571—26881669<span className='small'>&nbsp; 9:30—18:00</span></div>
              </div>
          </div>
        </div>
      </div>
      <div className="container">
        {/* <Header setFlag={props.setFlag} flag={props.flag} setUrl={props.setUrl} url={props.url} /> */}
        <div className="header">
          <div className="top-title">惠租 </div>
          <div className="top-content-top">
            全品类信用免押金租赁平台
          </div>
          <div className="top-content-center">
            为消费者和商家带来更高效更智能的租赁服务
          </div>
          <div className="top-content-bottom" href="lianxi">
            <a href="#lianxi">商家入驻</a>
          </div>
          <div className="pic-left"></div>
        </div>
        <div className='info'>
          <div className='info-center'>
            {newsData.map((item,index)=>
              <div key={index} className='news-container'>
                <a key={index} className='newsContent' href={item.url} target="_blank" rel='noreferrer'>
                  <div className='news-card'>
                    <h3 className='news-title'>{item.title}</h3>
                    {/* <div className='news-content'>{item.content}</div> */}
                  </div>
                </a>
                {index===newsData.length-1?null:<div className='news-line'></div>}
              </div>
            )}
          </div>
        </div>
      </div>
      <div className="container1">
        {/* 数据展示部分 */}
        <div className="data-title">
          <div className="data-time">
            截止于：<span className="time">{time}</span>
          </div>
          {/* <hr className="hr" /> */}
          <div className="data-datas">
            <div className="data-data" style={{ marginLeft: "0" }}>
              <span className="data-span">
                <span className="data-span-font">{store}</span>
                <span className="data-span1">用户数</span>
              </span>
            </div>
            <div className="line"></div>
            <div className="data-data">
              <span className="data-span">
                <span className="data-span-font">2000+</span>
                <span className="data-span1">卖家使用惠租</span>
              </span>
            </div>
            <div className="line"></div>
            <div className="data-data">
              <span className="data-span">
                <span className="data-span-font">10万+</span>
                <span className="data-span1">在租商品数</span>
              </span>
            </div>
            <div className="line"></div>
            <div className="data-data">
              <span className="data-span">
                <span className="data-span-font">25+</span>
                <span className="data-span1">覆盖省份</span>
              </span>
            </div>
          </div>
        </div>
      </div>
      {/* 三大核心产品 */}
      <div className="product">
        <div className="product-title" style={{ cursor: "pointer" }}>
          惠租三大核心产品
        </div>
        <hr className="hr-orange" />
        <div className="product-title-bottom">
          一站式、支付、流量、免押金风控、司法，你需要的我们都有
        </div>
        <div className="product-icon">
          <div className="store-box" id="storeIcon" onMouseOver={()=>setChannel(1)}>
            <div className="product-icon-store"></div>
            <div className="store">
              租赁店铺
              <div className="triangle">
              </div>
            </div>
            <div className="store-hover">

            </div>
          </div>
          {/* 代替边距解决滑入错位问题 */}
          <div style={{width:216}}></div>
          <div className="app-box" id="appIcon" onMouseOver={()=>setChannel(2)}>
            <div className="product-icon-app"></div>
            <div className="product-content-app">
              <div className="app">
                租赁小程序
                <div className="triangle"></div>
              </div>
            </div>
          </div>
          <div style={{width:216}}></div>
          <div className="pc-box" id="pcIcon" onMouseOver={()=>setChannel(3)}>
            <div className="product-icon-pc"></div>
            <div className="product-content-pc">
              <div className="pc">
                PC独立站/APP
                <div className="triangle"></div>
              </div>
            </div>
          </div>
        </div>
        <div className="product-content-store" id="storeContent" style={{ display:channel===1?"block":"none" }}>
          <span className="product-content-title">
            0成本快速开启你的租赁店铺
          </span>
          <ul className="product-contents">
            <li><div className="orangeCircle"></div> 0成本，1分钟免费快速开店</li>
            <li><div className="orangeCircle"></div> 线上，线下，微信，支付宝等流量全渠道通用</li>
            <li><div className="orangeCircle"></div> 导购选品，免押下单，租金扣款，逾期追款一站式闭环</li>
            <li><div className="orangeCircle"></div> 手机数码/母婴图书/服饰包箱/汽车电动车/美妆个护等全品类通用</li>
          </ul>
          <div className="product-button">
            <div className="product-button-left">
              <a href="#lianxi">免费试用</a>
            </div>
            <div className="product-button-right">
              <span onClick={changePages}>查看详情</span>
            </div>
          </div>
          <div className="product-rightBox">
            <div className="rightStore"></div>
          </div>
        </div>
        <div className="product-content-store" style={{ display:channel===3?"block":"none" }} id="pcContent">
          <span className="product-content-title">
            你自己域名的独立站或专属品牌的APP
          </span>
          <ul className="product-contents">
            <li><div className="orangeCircle"></div> 你自己域名的独立站或专属品牌的APP</li>
            <li><div className="orangeCircle"></div> 适用于企业租赁场景</li>
            <li><div className="orangeCircle"></div> 无需自建研发团队，低成本快速拥有</li>
            {/* <li>手机数码/母婴图书/服饰包箱/汽车电动车/美妆个护等全品类通用</li> */}
          </ul>
          <div className="product-button">
            <div className="product-button-left">
              <a href="#lianxi">免费试用</a>
            </div>
            <div className="product-button-right">
              <span onClick={changePages}>查看详情</span>
            </div>
          </div>
          <div className="product-rightBox">
            <div className="rightPc"></div>
          </div>
        </div>
        <div className="product-content-store" style={{ display:channel===2?"block":"none" }} id="appContent">
          <span className="product-content-title">
            你自己的独立品牌
          </span>
          <ul className="product-contents">
            {/* <li><div className="orangeCircle"></div> 你自己的独立品牌</li> */}
            <li><div className="orangeCircle"></div> 基于云服务的支付宝小程序</li>
            <li><div className="orangeCircle"></div> 低成本享有租物流量红利</li>
            <li><div className="orangeCircle"></div> 无需自建研发团队，低成本快速拥有</li>
          </ul>
          <div className="product-button">
            <div className="product-button-left">
              <a href="#lianxi">免费试用</a>
            </div>
            <div className="product-button-right">
              <span onClick={changePages}>查看详情</span>
            </div>
          </div>
          <div className="product-rightBox">
            <div className="rightApp"></div>
          </div>
        </div>
      </div>
      {/* 三重保障部分 */}
      <div className="guarantee">
        <div className="guarantee-title">
          惠租三重保障
        </div>
        <hr className="hr-orange" />
        <div className="guarantee-title-bottom">
          全方位为你的资产保驾护航
        </div>
        <div className="guarantee-content">
          <div className="left-div">
            {/* 三重保障左边部分 */}
            <div className="guarantee-content-left">
              <div className="box" style={{ height: 36 }}></div>
              <div className="guarantee-left-icon">
              </div>
              <span className="guarantee-left-title">
                风控免押金
              </span>
              <span className="guarantee-left-tip">基于神经网络算法 <br />训练的免押金风控模型；综合逾期率&lt;1%</span>
              <div className="guarantee-left-footer">综合逾期率&lt;1%</div>
            </div>
            {/* 左边滑入后 */}
            <div className="guarantee-content-left-hover">
              <span className="guarantee-left-title">风控免押金</span>
              <span className="guarantee-left-tip">
                采用芝麻信用及多头贷，司法数据，社交关联，租赁等共享大数据源，基于神经网络算法训练的免押金风控模型；综合逾期率&lt;1%
              </span>
              <div className="guarantee-left-footer">综合逾期率&lt;1%</div>
            </div>
          </div>
          <div className="div"></div>
          <div className="center-div">
            {/* 三重保障中间部分 */}
            <div className="guarantee-content-center">
              <div className="box" style={{ height: 36 }}></div>
              <div className="guarantee-center-icon">
              </div>
              <span className="guarantee-center-title">
                司法仲裁
              </span>
              <span className="guarantee-center-tip">与仲裁委系统级对接<br /> 案件证据一键<br /> 提交审理</span>
              <div className="guarantee-center-footer">一键提交审理</div>
            </div>
            {/* 三重保障中间部分滑入后 */}
            <div className="guarantee-content-center-hover">
              <span className="guarantee-center-title">司法仲裁</span>
              <span className="guarantee-center-tip">
                与仲裁委系统级对接，案件证据一键提交审理，从提交至判决低至一个月时间内；同时可采用法院强制执行，失信人等多维司法途径保障资产安全
              </span>
              <div className="guarantee-center-footer">一键提交审理</div>
            </div>
          </div>
          <div style={{width:120}}></div>
          <div className="right-div">
            {/* 三重保障右边部分 */}
            <div className="guarantee-content-right">
              <div className="box" style={{ height: 36 }}></div>

              <div className="guarantee-right-icon">
              </div>
              <span className="guarantee-right-title">
                区块链及保险
              </span>
              <span className="guarantee-right-tip">永久存证，不可篡改；可购买人保，财产损失后保险赔偿</span>
              <div className="guarantee-right-footer">永久存证，不可篡改</div>
            </div>
            {/* 三重保障右边部分滑入后 */}
            <div className="guarantee-content-right-hover">
              <span className="guarantee-right-title">区块链及保险</span>
              <span className="guarantee-right-tip">
                基于蚂蚁区块链的租务证据链取证，留存电子合同与订单以及物流等全流程租物数据；永久存证，不可篡改；可购买人保，财产损失后保险赔偿
              </span>
              <div className="guarantee-right-footer">永久存证，不可篡改</div>
            </div>
          </div>
        </div>
      </div>
      {/* 业务升级部分 */}
      <div className="business">
        <div className="business-left">
        </div>
        <div className="business-right">
          <span className="business-right-top">全行业全场景助你业务升级</span>
          <span className="business-right-bottom">只要能租的行业/产品，我们都为你提供解决方案</span>
        </div>
      </div>
      {/* 消费者业务 */}
      <div className="consumption">
        <div className="consumption-title">
          惠租消费者业务
        </div>
        <hr className="hr-orange" />
        <div className="consumption-title-bottom">
          为消费者提供免押金租物服务，让生活更简单
        </div>
        <div className="consumption-content">
          <div className="consumption-picFirst">
            <div className="pic1-div">
              <div className="pic1">
              </div>
              <div className="hover1">
                <div className="hover1-icon">
                </div>
                <div className="hover1-font">
                  支付宝扫一扫
                </div>
              </div>
            </div>
            <div className="pic2-div">
              <div className="pic2">
              </div>
              <div className="hover2">
                <div className="hover2-icon">
                </div>
                <div className="hover2-font">
                  支付宝扫一扫
                </div>
              </div>
            </div>
            <div className="pic3-div">
              <div className="pic3">
              </div>
              <div className="hover3">
                <div className="hover3-icon">
                </div>
                <div className="hover3-font">
                  支付宝扫一扫
                </div>
              </div>
            </div>
          </div>
          <div className="consumption-picSecond">
            <div className="pic4-div">
              <div className="pic4">
              </div>
              <div className="hover4">
                <div className="hover4-icon">
                </div>
                <div className="hover4-font">
                  支付宝扫一扫
                </div>
              </div>
            </div>
            <div className="pic5-div">
              <div className="pic5">
              </div>
              <div className="hover5">
                <div className="hover5-icon">
                </div>
                <div className="hover5-font">
                  支付宝扫一扫
                </div>
              </div>
            </div>
            <div className="pic6-div">
              <div className="pic6">
              </div>
              <div className="hover6">
                <div className="hover6-icon">
                </div>
                <div className="hover6-font">
                  支付宝扫一扫
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="consumption-button" onClick={changeLeaseShow}>
          我要租赁
        </div>
        <Modal
          visible={leaseShow}
          onCancel={canleLeaseShow}
          footer={null}
          className="modalLease"
          getContainer={false}
        >
          <div className="title">我要租赁</div>
          <div className="icon">
            <div className="icon-pic"></div>
            <div className="icon-title">支付宝扫一扫，立即租赁</div>
          </div>

        </Modal>
      </div>
      {/* 联系我们 */}
      <div className="contact" id="lianxi">
        <div className="contact-title">
          联系我们
        </div>
        <div className="contact-content">
          <div className="contact-content-left">
            <div className="contact-left-title">
              提交步骤
            </div>
            <div className="contact-left-box">
              <div className="contact-box-title">
                拨打咨询电话，立即领取开店大礼包
              </div>
            </div>
            <div className="contact-left-phone">
              <span className="contact-phoneNumber">0571—26881669</span>
              &nbsp;&nbsp;&nbsp;&nbsp;
              <span className="contact-time">9:30—18:00</span>
            </div>
          </div>
          <div className="contact-form">
            <div className="contact-form-title">立即预约</div>

            <Form name="basic" onFinish={onFinish} form={form}>
              <Form.Item name="contactName" rules={[{ required: true,message:'您的姓名为必填' }]} style={{ marginTop: 30 }}>
                <div className="form-input">
                  <Input type="text" className="input-style" placeholder="请输入您的姓名" />
                </div>
              </Form.Item>
              <Form.Item name="enterpriseName" rules={[{ required: true,message:'企业名称为必填'}]}>
                <div className="form-input">
                  <Input type="text" className="input-style" placeholder="请输入企业名称" />
                </div>
              </Form.Item>
              <Form.Item name="telephone" rules={[{ required: true , message:'手机号码为必填' }]}>
                <div className="form-input">
                  <Input type="text" className="input-style" placeholder="请输入手机号码" />
                </div>
              </Form.Item>
              <Form.Item name="categories">
                <div className="form-input">
                  <Input type="text" className="input-style" placeholder="请输入意向类目，如：手机" />
                </div>
              </Form.Item>
              {/* <div className="form-button" onClick={onFinish}>
                提交信息
              </div> */}
              <Button htmlType="submit" className="form-button">提交信息</Button>
            </Form>


          </div>
        </div>
      </div>
      <Footer />
    </div>
  )
}

export default IndexContent;