import React, { useState } from "react";
import { Modal, Input, Form, Button, message } from 'antd';
import axios from 'axios';
import './content.less'

function StoreContent() {

  const [form] = Form.useForm();

  const [isModalVisible, setIsModalVisible] = useState(false);
  const [isModalShow, setIsModalShow] = useState(false);

  const handleCancel = () => {
    setIsModalVisible(false)
  }

  const handleClick = () => {
    setIsModalVisible(true)
  }

  const cancelModal = () => {
    setIsModalShow(false)
  }

  const showModal = () => {
    setIsModalShow(true)
  }

  const submitForm = (data) => axios({
    headers: {
      'Content-Type': 'application/json'
    },
    method: 'post',
    url: '/consumer-zhifubao/aliPay/user/submitUserConsultInfo',
    responseType: 'json',
    data
  })

  const onFinish = (values) => {
    form.resetFields();
    console.log("666", values);
    submitForm({
      ...values
    }).then(res => {
      // console.log("666", res)
      if (res?.data?.code === 1) {
        setIsModalShow(false)
        message.success(res.data.msg);
      } else {
        message.error(res.data.msg);
      }
    });
  };

  return (
    <div className="storeContent">
      <div className="process" />
      <div className="drainage">
        <div className="drainage-icon" />
        <div className="drainage-content">
          <div className="content-title">公域流量引流，尽享业务增长</div>
          <div className="content-content">
            上传商品享受惠租千万用户流量红利，新店更有新店<br />
            礼包，获得翻倍业务增长
          </div>
          <div className="content-button" onClick={handleClick}>立即咨询</div>
        </div>
      </div>
      <div className="openShop">
        <div className="openShop-content">
          <div className="openShop-title">0成本“一键开店”</div>
          <div className="openShop-contents">助力行业增长，0成本即可开店；1分钟即可上传自己的商品</div>
          <div className="openShop-contents">店铺支持微信H5，支付宝小程序等多种形式</div>
          <div className="openShop-contents">助你获得全网流量，打造最佳用户体验</div>
          <div className="content-button" onClick={handleClick}>立即咨询</div>
        </div>
        <div className="openShop-icon" />
      </div>
      <div className="steps"><div className="fourSteps" /></div>
      <div className="otherStore">
        <div className="otherStore-title">他们都在用惠租</div>
        <div className="otherStore-tip">排名不分先后顺序</div>
        <div className="icon-box">
          <div className="zhezhaoceng"></div>
          <div className="store-icons">
            <div className="store-icon">
              <div className="icon">
                <div className="icon1-bk"></div>
                <div className="icon1-head"></div>
                <div className="icon1-title">兔巴租机</div>
                <div className="icon1-content">Bieber建的全品类 <br /> 店铺</div>
              </div>
              <div className="icon">
                <div className="icon2-bk"></div>
                <div className="icon2-head"></div>
                <div className="icon1-title">大叔数码</div>
                <div className="icon1-content">全品类店铺</div>
              </div>
              <div className="icon">
                <div className="icon3-bk"></div>
                <div className="icon3-head"></div>
                <div className="icon1-title">熊猫绘本</div>
                <div className="icon1-content">主营儿童绘本租赁 <br /> 服务</div>
              </div>
              <div className="icon">
                <div className="icon4-bk"></div>
                <div className="icon4-head"></div>
                <div className="icon1-title">租元璋</div>
                <div className="icon1-content">电脑租赁</div>
              </div>
              <div className="icon">
                <div className="icon5-bk"></div>
                <div className="icon5-head"></div>
                <div className="icon1-title">恒乐嘉手机</div>
                <div className="icon1-content">专业手机租赁</div>
              </div>
            </div>
            <div className="store-icon">
              <div className="icon">
                <div className="icon1-bk"></div>
                <div className="icon1-head"></div>
                <div className="icon1-title">兔巴租机</div>
                <div className="icon1-content">Bieber建的全品类 <br /> 店铺</div>
              </div>
              <div className="icon">
                <div className="icon2-bk"></div>
                <div className="icon2-head"></div>
                <div className="icon1-title">大叔数码</div>
                <div className="icon1-content">全品类店铺</div>
              </div>
              <div className="icon">
                <div className="icon3-bk"></div>
                <div className="icon3-head"></div>
                <div className="icon1-title">熊猫绘本</div>
                <div className="icon1-content">主营儿童绘本租赁 <br /> 服务</div>
              </div>
              <div className="icon">
                <div className="icon">
                  <div className="icon4-bk"></div>
                  <div className="icon4-head"></div>
                  <div className="icon1-title">租元璋</div>
                  <div className="icon1-content">电脑租赁</div>
                </div>
              </div>
              <div className="icon">
                <div className="icon5-bk"></div>
                <div className="icon5-head"></div>
                <div className="icon1-title">恒乐嘉手机</div>
                <div className="icon1-content">专业手机租赁</div>
              </div>
            </div>
          </div>
        </div>

      </div>
      <div className="service">
        <div className="service-title">立即扫码咨询，领取您的专属解决方案</div>
        <div className="hrs"/>
        <div className="service-action">
          <div className="code-icon" />
          <div className="service-content">
            <div className="content-first">马上扫码添加客户经理</div>
            <div className="content-second">或者您也可以先</div>
            <div className="content-third" onClick={showModal}>免费试用</div>
          </div>
          <div className="service-icon" />
        </div>
      </div>
      <Modal
        visible={isModalVisible}
        onCancel={handleCancel}
        footer={null}
        className="modalInquiry"
        getContainer={false}
        width={600}
      >
        <div className="title">立即咨询</div>
        <div className="pic-box">
          <div className="pic"></div>
          <div className="tip">微信扫一扫</div>
        </div>
        <div className="content">
          微信扫一扫与客服经理 <br />
          一对一沟通开店
        </div>
      </Modal>
      <Modal
        visible={isModalShow}
        onCancel={cancelModal}
        footer={null}
        className="modalAppointment"
        getContainer={false}
        width={570}
      >
        <Form name="basic" onFinish={onFinish} form={form}>
          <div className="title">立即预约</div>
          <div className="form">
            <div className="form-div" style={{ marginTop: 20 }}>
              <span className="span" style={{ paddingRight: 5.97 }}>姓<span style={{visibility:"hidden"}}>姓名</span>名：</span>
              <Form.Item name="contactName" rules={[{ required: true, message: '您的姓名为必填' }]}>
                <div className="inputs" style={{ marginLeft: -5.97 }}>
                  <Input type="text" placeholder="请输入您的姓名" className="input" />
                </div>
              </Form.Item>
            </div>
            <div className="form-div">
              <span className="span">企业名称：</span>
              <Form.Item name="enterpriseName" rules={[{ required: true, message: '企业名称为必填' }]}>
                <div className="inputs">
                  <Input type="text" placeholder="请输入企业名称" className="input" />
                </div>
              </Form.Item>
            </div>
            <div className="form-div">
              <span className="span">手机号码：</span>
              <Form.Item name="telephone" rules={[{ required: true, message: '手机号码为必填' }]}>
                <div className="inputs">
                  <Input type="text" placeholder="请输入手机号码" className="input" />
                </div>
              </Form.Item>
            </div>
            <div className="form-div">
              <span className="span">意向类目：</span>
              <Form.Item name="categories">
                <div className="inputs">
                  <Input type="text" placeholder="请输入意向类目，如：手机" className="input" />
                </div>
              </Form.Item>
            </div>
          </div>
          <div className="buttonBox">
            <Button className="button" htmlType="submit">提交信息</Button>
          </div>
        </Form>

      </Modal>
    </div>
  )
}

export default StoreContent