import React,{useState} from 'react';
import { Modal } from 'antd';
import './content.less';

function PcContent() {

  const [isModalVisible, setIsModalVisible] = useState(false);

  const handleCancel = () => {
    setIsModalVisible(false)
  }
  
  const handleClick = () => {
    setIsModalVisible(true)
  } 

  return (
    <div className="pc">
      <div className="pc-first">
        <div className="f-content">
          <div className="f-title">PC独立站</div>
          <div className="f-tip">适用于面向B端客户的企业租赁场景</div>
          <div className="f-button" onClick={handleClick}>立即咨询</div>
        </div>
        <div className="f-icon">
        </div>
      </div>
      <div className="pc-second">
        <div className="s-icon">
        </div>
        <div className="s-content">
          <div className="s-title">快速建立你的APP</div>
          <div className="s-tip">包含安卓和IOS版本，快速上架应用商场，适用于各类租赁场景</div>
          <div className="s-button" onClick={handleClick}>立即咨询</div>
        </div>
      </div>
      <div className="pc-third">
        <div className="icon-title">完美的一站式租赁流程</div>
        <div className="t-icons">
          <div className="t-icon1"></div>
          <div className="t-icon2"></div>
          <div className="t-icon3"></div>
          <div className="t-icon4"></div>
          <div className="t-icon5"></div>
          <div className="t-icon6"></div>
        </div>
        <div className="t-tips">
          <div className="tips1">招商店铺入驻</div>
          <div className="tips2">商品管理</div>
          <div className="tips3">交易管理</div>
          <div className="tips4">风控管理</div>
          <div className="tips5">租后管理</div>
          <div className="tips6">财务结算</div>
        </div>
      </div>
      <div className="pc-service">
        <div className="service-title">马上开始搭建您的线上店铺</div>
        <div className="service-button" onClick={handleClick}>立即咨询</div>
      </div>
      <Modal
        visible={isModalVisible}
        onCancel={handleCancel}
        footer={null}
        className="modalInquiry"
        getContainer={false}
        width={600}
      >
        <div className="title">立即咨询</div>
        <div className="pic-box">
          <div className="pic"></div>
          <div className="tip">微信扫一扫</div>
        </div>
        <div className="content">
          微信扫一扫与客服经理 <br />
          一对一沟通开店
        </div>
      </Modal>
    </div>
  )
}

export default PcContent;