import React, { useState, FC } from 'react';
import { Popup, Collapse, Space, Button,Modal } from 'antd-mobile';
import axios from 'axios';
import ModalForm from './modalForm';
import LeaseForm from './leaseForm';

import './index.less';
import './../../../App.css';


import computer  from '../../../官网切图/qiyezuling/computer.png'
import deskbook  from '../../../官网切图/qiyezuling/deskbook.png'
import print  from '../../../官网切图/qiyezuling/print.png'
import phone  from '../../../官网切图/qiyezuling/phone.png'
import notebook  from '../../../官网切图/qiyezuling/notebook.png'
import otherservice from '../../../官网切图/qiyezuling/otherservice.png'
const Header = (props) =>{
    const [visible, setVisible] = useState(false)
    const [isModalLease, setisModalLease] = useState(false);
    
    const cancleLease = () => {
        setisModalLease(false)
      }
    
    const showLease = () => {
        setisModalLease(true)
    }

    //获取子组件idList
    const getLeaseFormIdlist = (list)=> {
        //console.log(list.join(','),'list')
        return list.join(',');
    }
    // const [isModalLease, setIsModalLease] = useState(false);
    // const cancelModalLease = () => {
    //     setIsModalLease(false)
    //   }
    
    // const showLease = () => {
    //     setIsModalLease(true)
    //   }
    //跳转页面
    const changePages = (num) => {
        // props.setFlag(1)
        window.location.href=`${window.location.origin}${window.location.pathname}?flag=${num}`
    }

    // const showLease=(e) => {
    //     Modal.alert({
    //       title: '企业租赁',
    //       content: <LeaseForm 
        
    //       //   changeStyle={changeStyle}
    //     //   picList={picList}
    //     //   idList={idList}

    //       getLeaseFormIdlist={getLeaseFormIdlist}
    //       />,
    //       showCloseButton: true,
    //       disableBodyScroll:false,
    //     })
    //   }

    const showMoal=(e)=>{
        Modal.alert({
            title: '租赁风控报告',
            content: <ModalForm />,
            showCloseButton: true,
            disableBodyScroll:false,
            
          })
    }



    return (
        <div className="header">
           <div className='header-top'>
               <div className='header-top-icon'></div>
               <div className='header-top-hamburger' onClick={()=>setVisible(true)}></div>
               <Popup
               className='header-top-Popup'
                visible={visible}
                // onMaskClick={() => {
                //     setVisible(false)
                // }}
                onClose={() => {
                    setVisible(false)
                }}
                bodyStyle={{ width: '100vw' }}
                position='left'
                showCloseButton
                >
                    <div　className='header-top-Popup-title' onClick={()=>changePages(1)}>首页</div>
                    <div class="underline"></div>
                    <Collapse 
                    className='header-top-Popup-fold' 
                    accordion
                    defaultActiveKey={['1']}>
                        <Collapse.Panel className='header-top-Popup-fold-item' key='1' title='C端服务'>
                            <div className='header-top-Popup-fold-consumer'>
                                <div className='header-top-Popup-fold-consumer-box'>
                                    <div className='header-top-Popup-fold-consumer-box-iconFir'></div>
                                    <div className='header-top-Popup-fold-consumer-box-tip'>支付宝小程序</div>
                                </div>
                                <div className='header-top-Popup-fold-consumer-box'>
                                    <div className='header-top-Popup-fold-consumer-box-iconSec'></div>
                                    <div className='header-top-Popup-fold-consumer-box-tip'>Android 客户端</div>
                                </div>
                                <div className='header-top-Popup-fold-consumer-box'>
                                    <div className='header-top-Popup-fold-consumer-box-iconThi'></div>
                                    <div className='header-top-Popup-fold-consumer-box-tip'>IOS 客户端</div>
                                    <div className='header-top-Popup-fold-consumer-box-subTip'>(仅支持微信扫一扫)</div>
                                </div>
                            </div>
                        </Collapse.Panel>
                        <Collapse.Panel className='header-top-Popup-fold-item' key='2' title='B端服务(云服务)'>
                            <div className="header-top-Popup-fold-cloudconsumer">
                                <div className="header-top-Popup-fold-cloudconsumer-title">商家产品</div>
                                <div className="header-top-Popup-fold-cloudconsumer-content">
                                    <a className="header-top-Popup-fold-cloudconsumer-content-item" href={window.location.origin+window.location.pathname+'?flag=2'} >租赁店铺</a>
                                    <a className="header-top-Popup-fold-cloudconsumer-content-item" href={window.location.origin+window.location.pathname+'?flag=3'} target="_blank" rel='noreferrer'>租赁小程序</a>
                                    <a className="header-top-Popup-fold-cloudconsumer-content-item" href={window.location.origin+window.location.pathname+'?flag=4'} target="_blank" rel='noreferrer'>PC独立站&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</a>
                                </div>
                                <div className="header-top-Popup-fold-cloudconsumer-title">
                                    适用场景/行业 
                                </div>
                                <div className="header-top-Popup-fold-cloudconsumer-content">
                                    <div className="header-top-Popup-fold-cloudconsumer-content-item">
                                        <span className="header-top-Popup-fold-cloudconsumer-content-font">零售门店</span>
                                        <span className="header-top-Popup-fold-cloudconsumer-content-font">手机数码</span>
                                        <span className="header-top-Popup-fold-cloudconsumer-content-font">家具家电</span>
                                        <span className="header-top-Popup-fold-cloudconsumer-content-font">微信私域</span>
                                    </div>
                                    <div className="header-top-Popup-fold-cloudconsumer-content-item">
                                        <span className="header-top-Popup-fold-cloudconsumer-content-font">母婴图书&nbsp;</span>
                                        <span className="header-top-Popup-fold-cloudconsumere-content-font">游戏电玩</span>
                                        <span className="header-top-Popup-fold-cloudconsumer-content-font">企业办公</span>
                                        <span className="header-top-Popup-fold-cloudconsumer-content-font">景区酒店</span>   
                                    </div>
                                    <div className="header-top-Popup-fold-cloudconsumer-content-item">
                                        <span className="header-top-Popup-fold-cloudconsumer-content-font">电动车</span>
                                        <span className="header-top-Popup-fold-cloudconsumer-content-font"> 自助设备</span>
                                        <span className="header-top-Popup-fold-cloudconsumer-content-font">支付宝生态</span>
                                        <span className="header-top-Popup-fold-cloudconsumer-content-font">更多敬请期待</span>
                                        {/* <span className="header-top-Popup-fold-cloudconsumer-title-bottom-content-fonts" style={{color: 'rgba(235, 58, 50, 0.5)'}}>更多敬请期待</span> */}
                                    </div>
                                </div>
                            </div>
                        </Collapse.Panel>
                        </Collapse>
                    <div className='header-top-Popup-button' onClick={showLease}>企业租赁</div>
                    <div className='header-top-Popup-button' onClick={showMoal}>风控报告</div>
                    <div className='header-top-Popup-button' onClick={()=>changePages(5)}>新闻中心</div>
                    <div className='header-top-Popup-button' onClick={()=>changePages(6)}>关于我们</div>
                    <div className='header-top-Popup-button'>
                        <a
                            href="https://business.huizustore.com/#/business/user/login"
                            target="_blank"
                            rel="noreferrer"
                        >
                            商家后台
                        </a>
                    </div>
                </Popup>
            </div> 
            
            <Modal
                visible={isModalLease}
                showCloseButton
                onClose={cancleLease}
                title='企业租赁'
                content={<LeaseForm 
                    setisModalLease={setisModalLease}
                    getLeaseFormIdlist={getLeaseFormIdlist}
                />
                } 
            />
            {/* <Modal
                visible={isModalLease}
                className="modallease"
                title= '企业租赁'
                content={<LeaseForm />}
                showCloseButton
                onClose={cancelModalLease}
                // disableBodyScroll:false
                closeOnAction
                onClose={() => {
                    setVisible(false)
                    }}
                actions={[
                {
                    key: 'confirm',
                    text: '提交委托',
                },
                ]}
            /> */}
        </div>
    )
}

export default Header;