import React, { useState } from 'react';
import { Modal } from 'antd';
import './content.less';

function AppContent() {

  const [isModalVisible, setIsModalVisible] = useState(false);

  const handleCancel = () => {
    setIsModalVisible(false)
  }

  const handleClick = () => {
    setIsModalVisible(true)
  }

  return (
    <div className="appContent">
      <div className="appContent-function">
        <div className="fun-title">丰富的功能，适用于不同的租赁场景和需求</div>
        <div className="fun-tip">一站式闭环交易能力，金融级的租物交易能力</div>
        <div className="fun-icons">
          <div className="icons-first">
            <div className="icon-1">
              <div className="icon-tip">招商店铺入驻</div>
            </div>
            <div className="icon-2">
              <div className="icon-tip">商品管理</div>
            </div>
            <div className="icon-3">
              <div className="icon-tip">订单管理</div>
            </div>
            <div className="icon-4">
              <div className="icon-tip">资金结算</div>
            </div>
            <div className="icon-5">
              <div className="icon-tip">渠道分销</div>
            </div>
          </div>
          <div className="icons-second">
            <div className="icon-6">
              <div className="icon-tip">免押金风控</div>
            </div>
            <div className="icon-7">
              <div className="icon-tip">区块链保险</div>
            </div>
            <div className="icon-8">
              <div className="icon-tip">司法仲裁</div>
            </div>
            <div className="icon-9">
              <div className="icon-tip">租金代扣</div>
            </div>
            <div className="icon-10">
              <div className="icon-tip">在线客服</div>
            </div>
          </div>
        </div>
      </div>
      <div className="appContent-applet">
        <div className="applet-title">租赁支付宝小程序</div>
        <div className="applet-tip">独立品牌，私域流量，让经营变得更简单</div>
        <div className="applet-content">
          <div className="applet-contents">
            <div className="cons-title">流量红利</div>
            <div className="cons-tip">支付宝租物流量红利，新消费新人群</div>
            <div className="cons-title">低成本</div>
            <div className="cons-tip">低成本建立您自己品牌的应用，运营维护更简单</div>
            <div className="cons-title">服务指导</div>
            <div className="cons-tip">VIP 1对1 专属运营指导，助您业务增长</div>
            <div className="cons-title">多渠道</div>
            <div className="cons-tip">包含微信H5和支付宝小程序，线上线下全渠道通用</div>
            <div className="cons-button" onClick={handleClick}>立即咨询</div>
          </div>
          <div className="iconBox">
            <div className="con-icon"></div>
          </div>
        </div>
      </div>
      <div className="appContent-programme">
        <div className="programme-title">为你提供线上运营全链路解决方案</div>
        <div className="programme-tip">多种业务模式均可支持</div>
        <div className="programme-icon">
          <div className="icon-left">
            <div className="left-before">
              <div className="title">自营模式</div>
            </div>
            <div className="left-hover">
              <div className="title">自营模式</div>
              <div className="tip">
                品类自营，出租你的商品，获得长期租金收益
              </div>
            </div>
          </div>
          <div className="icon-center">
            <div className="center-before">
              <div className="title">平台模式</div>
            </div>
            <div className="center-hover">
              <div className="title">平台模式</div>
              <div className="tip">招募商家入驻你的小程序，
                助你快速扩充业务品类，带
                来用户增长</div>
            </div>
          </div>
          <div className="icon-right">
            <div className="right-before">
              <div className="title">分销模式</div>
            </div>
            <div className="right-hover">
              <div className="title">分销模式</div>
              <div className="tip">支持招募线上及线下渠道代
                理，让线上渠道及线下门店
                为你推广商品，获取佣金收
                益，支持多级分销</div>
            </div>

          </div>
        </div>
      </div>
      <div className="steps">
        <div className="fourSteps" />
      </div>
      <div className="otherStore">
        <div className="otherStore-title">他们都在用惠租</div>
        <div className="otherStore-tip">排名不分先后顺序</div>
        <div className="icon-box">
          <div className="zhezhaoceng"></div>
          <div className="store-icons">
            <div className="store-icon">
              <div className="icon">
                <div className="icon1-bk"></div>
                <div className="icon1-head"></div>
                <div className="icon1-title">兔巴租机</div>
                <div className="icon1-content">Bieber建的全品类 <br /> 店铺</div>
              </div>
              <div className="icon">
                <div className="icon2-bk"></div>
                <div className="icon2-head"></div>
                <div className="icon1-title">大叔数码</div>
                <div className="icon1-content">全品类店铺</div>
              </div>
              <div className="icon">
                <div className="icon3-bk"></div>
                <div className="icon3-head"></div>
                <div className="icon1-title">熊猫绘本</div>
                <div className="icon1-content">主营儿童绘本租赁 <br /> 服务</div>
              </div>
              <div className="icon">
                <div className="icon4-bk"></div>
                <div className="icon4-head"></div>
                <div className="icon1-title">租元璋</div>
                <div className="icon1-content">电脑租赁</div>
              </div>
              <div className="icon">
                <div className="icon5-bk"></div>
                <div className="icon5-head"></div>
                <div className="icon1-title">恒乐嘉手机</div>
                <div className="icon1-content">专业手机租赁</div>
              </div>
            </div>
            <div className="store-icon">
              <div className="icon">
                <div className="icon1-bk"></div>
                <div className="icon1-head"></div>
                <div className="icon1-title">兔巴租机</div>
                <div className="icon1-content">Bieber建的全品类 <br /> 店铺</div>
              </div>
              <div className="icon">
                <div className="icon2-bk"></div>
                <div className="icon2-head"></div>
                <div className="icon1-title">大叔数码</div>
                <div className="icon1-content">全品类店铺</div>
              </div>
              <div className="icon">
                <div className="icon3-bk"></div>
                <div className="icon3-head"></div>
                <div className="icon1-title">熊猫绘本</div>
                <div className="icon1-content">主营儿童绘本租赁 <br /> 服务</div>
              </div>
              <div className="icon">
                <div className="icon">
                  <div className="icon4-bk"></div>
                  <div className="icon4-head"></div>
                  <div className="icon1-title">租元璋</div>
                  <div className="icon1-content">电脑租赁</div>
                </div>
              </div>
              <div className="icon">
                <div className="icon5-bk"></div>
                <div className="icon5-head"></div>
                <div className="icon1-title">恒乐嘉手机</div>
                <div className="icon1-content">专业手机租赁</div>
              </div>
            </div>
          </div>
        </div>

      </div>
      <div className="appContent-service">
        <div className="appContent-title">马上开始搭建您的租赁小程序</div>
        <div className="appContent-button" onClick={handleClick}>立即咨询</div>
      </div>
      <Modal
        visible={isModalVisible}
        onCancel={handleCancel}
        footer={null}
        className="modalInquiry"
        getContainer={false}
        width={600}
      >
        <div className="title">立即咨询</div>
        <div className="pic-box">
          <div className="pic"></div>
          <div className="tip">微信扫一扫</div>
        </div>
        <div className="content">
          微信扫一扫与客服经理 <br />
          一对一沟通开店
        </div>
      </Modal>
    </div>
  )
}

export default AppContent;